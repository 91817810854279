import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IconCross } from "../svg/icon-cross";
import { ProductImage } from "../../types/typescript-axios";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export type EditImage = ProductImage | {
  image: string
}
type Props = {
  images: EditImage[]
  setImages: Dispatch<SetStateAction<EditImage[]>>
}

export const ImageListWithInput = ({images, setImages}: Props) => {
  function inputFile(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files![0]
    const reader = new FileReader()
    reader.onload = () => {
      if (!reader.result) return
      setImages([{image: reader.result as string}, ...images])
    }
    reader.onerror = (error) => {
      console.log(error)
    }
    reader.readAsDataURL(file)
  }

  function removeImage(index: number) {
    if (index >= images.length) return
    images.splice(index, 1)
    setImages([...images])
  }

  function insertImages(index: number, otherIndex: number) {
    setImages(images => {
      let newImages = [...images]
      const indexImage = newImages[otherIndex]
      const otherImages = newImages.filter((_,idx)=>idx!==otherIndex)
      const headImages = otherImages.slice(0,index)
      const backImages = otherImages.slice(index)
      newImages = [...headImages, indexImage, ...backImages]

      return newImages
    })
  }

  return (
    <div className='mt-8 flex flex-wrap gap-[8px]'>
      {
        images.map((image, index) =>
          <ImageComponent image={image} key={index} index={index} removeImage={removeImage} insertImages={insertImages} />
        )
      }
      <input id='image-file' type='file' onChange={inputFile} hidden={true}/>
      <label
        htmlFor='image-file'
        className='flex justify-center items-center btn text-cosmebi-pink border-cosmebi-pink
        w-[170px] h-[170px] cursor-pointer border'
      >
        <span className='text-[15px] font-medium'>画像を選択する</span>
      </label>
    </div>
  )
}

type ImageComponentProps = {
  image: EditImage
  index: number
  insertImages: (index: number, otherIndex: number) => void
  removeImage: (index: number) => void
}

export const ImageComponent = ({ image, index, removeImage, insertImages }: ImageComponentProps) => {
  const [collected, drag] = useDrag(() => {
    return {
      type: 'image',
      item: { index },
      isDragging: (monitor) => {
        console.log('isDragging')
        return monitor.isDragging()
      }
    }
  })
  const [collectedProps, drop] = useDrop<{ index: number }>(() => {
    return {
      accept: 'image',
      drop: (item, monitor) => {
        console.log('drop!!!')
        console.log(item)
        insertImages(index, item.index)
      },
    }
  })
  function getImageSrc(image: EditImage) {
    return 'url' in image ? image.url : image.image
  }

  return (
    <div ref={drop}>
      <div className='relative' ref={drag}>
        <img src={getImageSrc(image)} className='w-[170px] h-[170px]'/>
        <div className='absolute top-0 left-0 w-full h-full opacity-0 hover:opacity-100'
             style={{backgroundColor: 'rgba(0,0,0,0.4)'}}>
          <IconCross className='absolute top-0 right-0 text-white w-[24px] h-[24px] cursor-pointer'
                     onClick={() => removeImage(index)}/>
        </div>
      </div>
    </div>
  )
}
