import React, { FormEvent, useState } from "react";
import { FetchListView } from "../../components/templates/FetchListView";
import { apiConfiguration } from "../../config/api-configuration";
import { useManager } from "../../components/contexts/ManagerProvider";
import { ElementInList } from "../../components/parts/ElementInList";
import { ConfirmModal } from "../../components/parts/ConfirmModal";
import { Tag } from "../../types/typescript-axios";
import { authorizedRequest } from "../../utils/api-request";

function ListElement({elem}: { elem: Tag }) {
  const manager = useManager()

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [newName, setNewName] = useState<string>(elem.name)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false)

  async function submit() {
    await authorizedRequest(manager, 'UpdateTag', {
      id: elem.id,
      name: newName,
    })
    elem.name = newName
    setIsEdit(false)
  }

  async function destroy() {
    await authorizedRequest(manager, 'DestroyTag', {
      id: elem.id,
    })
    window.location.reload()
  }

  return (
    <ElementInList>
      <div className='flex flex-1 items-center justify-between'>
        <div className='flex items-center'>
          <span className='mr-4'>{elem.id}</span>
          {
            isEdit ? (
              <span className='flex items-center'>
              <input value={newName} onChange={(e) => setNewName(e.target.value)}/>
              <button className='small-btn ml-2' onClick={submit}>決定</button>
              <button className='gray-btn small-btn ml-4' onClick={() => setIsEdit(false)}>キャンセル</button>
            </span>
            ) : (
              <span>{elem.name}</span>
            )
          }
        </div>
        <div>
          <button className='gray h-[42px] w-[68px]' onClick={() => setIsEdit(true)}>編集</button>
          <button className='small-btn red-btn ml-4' onClick={() => setShowConfirmDeleteModal(true)}>削除</button>
        </div>
      </div>
      {
        showConfirmDeleteModal && (
          <ConfirmModal
            title='タグの削除'
            content='削除したら戻すことはできません。本当に削除しますか？'
            okLogic={destroy}
            cancelLogic={() => setShowConfirmDeleteModal(false)}
          />
        )
      }
    </ElementInList>
  )
}

export const TagList: React.FunctionComponent = () => {
  const manager = useManager()
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [newTagName, setNewTagName] = useState<string>()

  async function fetch({
                         query,
                         page,
                         per
                       }: { query: string | null, page: number | null, per: number | null }): Promise<[Tag[], number]> {
    const response = await authorizedRequest(manager, 'IndexTags', {
      ...(query && {query}),
      ...(page && {page}),
      ...(per && {per}),
    })
    if (response.status !== 200) return [[], 0]

    const { data } = response
    return [data.tags, data.count]
  }

  async function submitCreate(e: FormEvent) {
    e.preventDefault()
    if (!newTagName) return

    await authorizedRequest(manager, 'CreateTag', {
      name: newTagName,
    })
    window.location.reload()
  }

  return (
    <>
      <FetchListView<Tag>
        title='タグ管理'
        fetch={fetch}
        ListElemComp={ListElement}
        create={() => setShowCreateModal(true)}
        hasSearch={false}
      />
      {
        showCreateModal && (
          <div
            className='absolute top-0 left-0 flex items-center justify-center w-screen h-screen'
            style={{backgroundColor: 'rgba(0, 0, 0, .5)'}}
            onClick={() => setShowCreateModal(false)}
          >
            <div className='flex flex-col bg-white p-10 rounded-xl' onClick={(e) => {
              e.stopPropagation()
            }}>
              <h2>タグの新規作成</h2>
              <form onSubmit={submitCreate}>
                <input placeholder='名前を入力' value={newTagName} onChange={(e) => setNewTagName(e.target.value)}/>
                <button className='ml-4 small-btn'>作成</button>
              </form>
            </div>
          </div>
        )
      }
    </>
  )
}
