import { FetchListView } from "../../components/templates/FetchListView";
import { GlobalNotification } from "../../types/typescript-axios";
import { authorizedRequest } from "../../utils/api-request";
import { useManager } from "../../components/contexts/ManagerProvider";
import { ElementInList } from "../../components/parts/ElementInList";
import { ConfirmModal } from "../../components/parts/ConfirmModal";
import React, { useState } from "react";
import {
  GlobalNotificationForm,
  GlobalNotificationFormData
} from "../../components/templates/global-notifications/GlobalNotificationForm";
import { toDateTimeString } from "../../utils/toDateTimeString";
import { globalNotificationLinkTypeString } from "../../models/global-notification/global-notification-link-type";
import { Modal } from "../../components/parts/Modal";

function ListElement({elem}: { elem: GlobalNotification }) {
  const manager = useManager()
  const [showEditModal, setShowEditModal] = useState(false)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false)

  async function update(data: GlobalNotificationFormData & { id: number }) {
    setShowEditModal(false)
    await authorizedRequest(manager, 'UpdateGlobalNotification', data)
    window.location.reload()
  }

  async function destroy() {
    await authorizedRequest(manager, 'DestroyGlobalNotification', {
      id: elem.id,
    })
    window.location.reload()
  }

  return (
    <ElementInList>
      <span className='inline-block w-[100px]'>{elem.id}</span>
      <span className='inline-block w-[200px]'>{elem.title}</span>
      <span className='inline-block w-[100px]'>{elem.linkType ? globalNotificationLinkTypeString(elem.linkType) : '' }</span>
      <span className='inline-block w-[200px]'>{elem.linkValue}</span>
      <span className='inline-block w-[200px]'>{toDateTimeString(elem.notifiedAt)}</span>
      <span className='inline-block w-[200px]'>
        {
          elem.childrenCreated ? '通知作成済' : '通知作成未'
        }
      </span>
      <div className='flex-1' />
      <button className='gray h-[42px] w-[68px]' onClick={() => setShowEditModal(true)}>編集</button>
      <button className='small-btn red-btn ml-4' onClick={() => setShowConfirmDeleteModal(true)}>削除</button>
      {
        showEditModal && (
          <Modal hide={() => setShowEditModal(false)}>
            <GlobalNotificationForm type='update' update={update} globalNotification={elem} />
          </Modal>
        )
      }
      {
        showConfirmDeleteModal && (
          <ConfirmModal
            title='通知の削除'
            content='削除したら戻すことはできません。本当に削除しますか？'
            okLogic={destroy}
            cancelLogic={() => setShowConfirmDeleteModal(false)}
          />
        )
      }
    </ElementInList>
  )
}

export const GlobalNotificationList = () => {
  const manager = useManager()
  const [showCreateModal, setShowCreateModal] = useState(false)

  async function fetch({
                         query,
                         page,
                         per
                       }: { query: string | null, page: number | null, per: number | null }): Promise<[GlobalNotification[], number]> {
    const response = await authorizedRequest(manager, 'IndexGlobalNotifications', {
      ...(query && {query}),
      ...(page && {page}),
      ...(per && {per}),
    })
    if (response.status !== 200) return [[], 0]

    const { data } = response
    return [data.globalNotifications, data.count]
  }

  async function createGlobalNotification(data: GlobalNotificationFormData) {
    setShowCreateModal(false)
    await authorizedRequest(manager, 'CreateGlobalNotification', data)
    window.location.reload()
  }

  return (
    <>
      <FetchListView<GlobalNotification>
        title='通知管理'
        fetch={fetch}
        ListElemComp={ListElement}
        hasSearch={false}
        create={() => setShowCreateModal(true)}
      />
      {
        showCreateModal && (
          <div
            className='fixed top-0 left-0 flex items-center justify-center w-screen h-screen'
            style={{backgroundColor: 'rgba(0, 0, 0, .5)'}}
            onClick={() => setShowCreateModal(false)}
          >
            <div className='flex flex-col bg-white p-10 rounded-xl' onClick={(e) => {
              e.stopPropagation()
            }}>
              <GlobalNotificationForm type='create' create={createGlobalNotification} />
            </div>
          </div>
        )
      }
    </>
  )
}
