import React, { useState } from "react";
import { FetchListView } from "../../components/templates/FetchListView";
import { useManager } from "../../components/contexts/ManagerProvider";
import { ElementInList } from "../../components/parts/ElementInList";
import { ConfirmModal } from "../../components/parts/ConfirmModal";
import { Customer, CustomerStatus, OfficialStatus } from "../../types/typescript-axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CustomerModal } from "./CustomerModal";
import { authorizedRequest } from "../../utils/api-request";
import { downloadCsv } from "../../lib/download-csv";
import { customerStatusToString } from "../../utils/customerStatusToString";
import { officialStatusToString } from "../../utils/officialStatusToString";
import Amba from "../../assets/images/customerStatus/ambassador-mark.png"
import Official from "../../assets/images/customerStatus/official-mark.png"

function ListElement({elem}: { elem: Customer }) {
  const manager = useManager()
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false)
  const [showResendEmailModal, setShowResendEmailModal] = useState<boolean>(false)

  async function destroy() {
    await authorizedRequest(manager, 'DestroyCustomer', {
      id: elem.id,
    })
    window.location.reload()
  }

  async function resendEmail() {
    await authorizedRequest(manager, 'ResendProvisionalRegistrationEmail', {
      id: elem.id
    })
    alert('送信が完了しました')
    setShowResendEmailModal(false)
  }

  return (
    <ElementInList>
      <div className='flex flex-1 items-center justify-between'>
        <div className='flex items-center gap-x-16'>
          <span>{elem.id}</span>
          <Link to={`/customers/${elem.id}`} className='w-[120px] list-link'>{elem.nicname}</Link>
          <span className='ml-4 w-[240px] break-words'>{elem.email}</span>
          <span className='ml-4'>{elem.name01} {elem.name02}</span>
        </div>

        <div>
          {
            elem.officialStatusId === 5 ?
            (<img src={Amba} className='w-[20px] h-[20px]'/>)
            : elem.officialStatusId === 3 ?
            (<img src={Official} className='w-[20px] h-[20px]'/>) : <div></div>

          }
        </div>
        <div>
          {
            elem.customerStatusId === 1 && (
              <button
                className='small-btn gray-btn mr-8'
                onClick={() => setShowResendEmailModal(true)}
              >
                仮会員メール再送
              </button>
            )
          }
          <button className='small-btn red-btn' onClick={() => setShowConfirmDeleteModal(true)}>削除</button>
        </div>
      </div>
      {
        showConfirmDeleteModal && (
          <ConfirmModal
            title='会員の削除'
            content='削除したら戻すことはできません。本当に削除しますか？'
            okLogic={destroy}
            cancelLogic={() => setShowConfirmDeleteModal(false)}
          />
        )
      }
      {
        showResendEmailModal && (
          <ConfirmModal
            title='仮会員メールの再送'
            content='本当に送信しますか？'
            okLogic={resendEmail}
            cancelLogic={() => setShowResendEmailModal(false)}
          />
        )
      }
    </ElementInList>
  )
}

export const CustomerList: React.FunctionComponent = () => {
  const manager = useManager()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  async function fetch({
                         query,
                         page,
                         per
                       }: { query: string | null, page: number | null, per: number | null }): Promise<[Customer[], number]> {
    const response = await fetchData({page, per, query})
    if (response.status !== 200) return [[], 0]

    const {data} = response
    return [data.customers, data.count]
  }

  async function csvDownload() {
    const adminStagingURL ="admin.democos.com"
    const adminProductionURL ="management-admin.cosmebi.jp"
    const userStagingURL = "www.democos.com"
    const userProductionURL = "www.cosmebi.jp"

    const hostName = window.location.hostname === adminProductionURL ? userProductionURL :
                      window.location.hostname === adminStagingURL ? userStagingURL : 'localhost'

    const query = searchParams.get('query')
    const response = await fetchData(({page: 1, per: 5000, query}))
    if (response.status !== 200) return
    if (manager?.attributes?.email) {
      const email = manager.attributes.email
      const actionType = '会員管理CSV'
      await authorizedRequest(manager, 'CreateCsvLogs', {
        email,
        actionType
      })
    }

    const headers = [
      'ID', 'ニックネーム', 'メールアドレス', 'URL', 'ステータス',
      'オフィシャルステータス', '名字', '名前', '名字（カナ）', '名前（カナ）',
      '郵便番号', '県', '住所1', '住所2',
      '誕生日', '電話番号', 'Twitter', 'Instagram', 'Facebook',
    ]
    const {customers} = response.data
    downloadCsv(
      [
        headers,
        ...customers.map(customer => [
          customer.id,
          customer.nicname, customer.email,
          `${hostName}/profile/${customer.nicname}`,
          customerStatusToString(customer.customerStatusId),
          officialStatusToString(customer.officialStatusId),
          customer.name01, customer.name02, customer.kana01, customer.kana02,
          customer.postalCode, customer.pref?.name, customer.addr01, customer.addr02,
          customer.birth, customer.phoneNumber, customer.twitter, customer.instagtam,
          customer.facebook,
        ])
      ], '会員一覧.csv')
  }

  async function fetchData({query, page, per}: { query: string | null, page: number | null, per: number | null }) {
    const customerStatuses = searchParams.get('customer-statuses')?.split(',')?.map(s => Number(s))
    const officialStatuses = searchParams.get('official-statuses')?.split(',')?.map(s => Number(s))
    const birthFrom = searchParams.get('birthday-from') || undefined
    const birthTill = searchParams.get('birthday-till') || undefined
    const prefId = searchParams.get('pref') ? Number(searchParams.get('pref')) : undefined
    const phoneNumber = searchParams.get('phone') || undefined
    const twitter = searchParams.get('twitter') || undefined
    const facebook = searchParams.get('facebook') || undefined
    const instagtam = searchParams.get('instagtam') || undefined
    const createDateFrom = searchParams.get('create-from') || undefined
    const createDateTill = searchParams.get('create-till') || undefined
    const updateDateFrom = searchParams.get('update-from') || undefined
    const updateDateTill = searchParams.get('update-till') || undefined

    return await authorizedRequest(manager, 'IndexCustomers', {
      ...(query && {query}),
      ...(page && {page}),
      ...(per && {per}),
      ...(customerStatuses && {customerStatuses: customerStatuses as CustomerStatus[]}),
      ...(officialStatuses && {officialStatuses: officialStatuses as OfficialStatus[]}),
      ...(birthFrom && {birthFrom}),
      ...(birthTill && {birthTill}),
      ...(prefId && {prefId}),
      ...(phoneNumber && {phoneNumber}),
      ...(twitter && {twitter}),
      ...(facebook && {facebook}),
      ...(instagtam && {instagtam}),
      ...(createDateFrom && {createDateFrom}),
      ...(createDateTill && {createDateTill}),
      ...(updateDateFrom && {updateDateFrom}),
      ...(updateDateTill && {updateDateTill}),
    })
  }

  return (
    <>
      <FetchListView<Customer>
        title='会員一覧'
        fetch={fetch}
        ListElemComp={ListElement}
        headerElement={<CustomerModal csvDownload={csvDownload}/>}
        create={() => navigate('/customers/new')}
      />
    </>
  )
}
