import { Brand } from "../../types/typescript-axios";
import React, { FormEvent, useEffect, useState } from "react";
import { useManager } from "../contexts/ManagerProvider";
import { authorizedRequest } from "../../utils/api-request";

export const ChooseBrandModal = ({ choosed, cancel }: { choosed: (brand: Brand | undefined) => void, cancel: () => void }) => {
  const manager = useManager()

  const [choosedBrand, setChoosedBrand] = useState<Brand>()
  const [brands, setBrands] = useState<Brand[]>()
  const [searchWord, setSearchWord] = useState<string>('')

  useEffect(() => {
    async function fetchBrands() {
      const response = await authorizedRequest(manager, 'IndexBrands')
      if (response.status !== 200) return

      const { data: { brands }} = response
      setBrands(brands)
    }
    fetchBrands()
  }, [])

  async function searchBrand(e: FormEvent) {
    e.preventDefault()
    setChoosedBrand(undefined)
    const response = await authorizedRequest(manager, 'IndexBrands', {
      ...(searchWord && { query: searchWord }),
    })
    if (response.status !== 200) return

    const { data: { brands }} = response
    setBrands(brands)
  }

  return (
    <div className='fixed h-screen w-screen top-0 left-0 z-50 flex justify-center items-center' style={{ backgroundColor: 'rgba(0, 0, 0, .5)'}}>
      <div className='w-[80%] h-[80%] bg-white rounded-3xl p-[32px]'>
        <h2>ブランドの選択</h2>
        <p>最新の30件を表示します。検索するとその他の結果を表示できます。</p>
        <form className='flex items-center py-[12px]' onSubmit={searchBrand}>
          <input value={searchWord} onChange={(e) => setSearchWord(e.target.value)}/>
          <button className='gray-btn h-[42px] w-[42px]'>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path
                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
            </svg>
          </button>
        </form>
        <div className='border border-gray-400 rounded-md h-[400px] overflow-scroll'>
          {
            brands?.map(brand => (
              <div
                onClick={() => setChoosedBrand(brand)}
                className={`px-[24px] py-[12px] border-b border-b-gray-300 ${choosedBrand?.id === brand.id && 'bg-gray-200'}`}
              >
                { brand.name }
              </div>
            ))
          }
        </div>
        <div className='flex justify-center mt-[24px]'>
          <button className='gray-btn' onClick={cancel}>キャンセル</button>
          <button className='ml-14' onClick={() => choosed(choosedBrand)}>選択</button>
        </div>
      </div>
    </div>
  )
}
