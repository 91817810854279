import { DragEvent, ChangeEvent, useEffect, useState } from "react";
import _ from 'lodash'

export const ImageInput = ({ onFileInput }: { onFileInput: (file: string) => void }) => {
  const [uniqueId, setUniqueId] = useState<string>()

  useEffect(() => {
    setUniqueId(_.uniqueId('image-input-'))
  }, [])

  function inputFile(e: ChangeEvent<HTMLInputElement>) {
    readFile(e.target.files![0])
  }
  function dropHandler(e: DragEvent<HTMLLabelElement>) {
    const file = e.dataTransfer!.items![0].getAsFile()
    if (file) readFile(file)
  }

  function readFile(file: File) {
    const reader = new FileReader()
    reader.onload = () => {
      if (!reader.result) return
      onFileInput(reader.result as string)
    }
    reader.onerror = (error) => {
      console.log(error)
    }
    reader.readAsDataURL(file);
  }

  return (
    <div>
      <label
        htmlFor={uniqueId}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={(e) => e.preventDefault()}
        onDrop={(e) => { e.preventDefault(); dropHandler(e)}}
        className='flex justify-center items-center w-[240px] h-[68px] border-cosmebi-pink border rounded-xl text-cosmebi-pink cursor-pointer p-[8px]'
      >
        クリックするか、
        <br />
        画像をドラッグ&ドロップ
        <br />
        してください。
      </label>
      <input id={uniqueId} className='hidden' type='file' onChange={inputFile} accept="image/png, image/jpeg"/>
    </div>
  )
}
