import { Clinic, UserContentStatus } from "../../types/typescript-axios";
import { ElementInList } from "../../components/parts/ElementInList";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React from "react";
import { useManager } from "../../components/contexts/ManagerProvider";
import { FetchListView } from "../../components/templates/FetchListView";
import { toDateString } from "../../utils/toDateString";
import { toHourMinutesString } from "../../utils/toHourMinutesString";
import { ReportModal } from "./ReportModal";
import { authorizedRequest } from "../../utils/api-request";
import { downloadCsv } from "../../lib/download-csv";
import { toDateTimeString } from "../../utils/toDateTimeString";
import { userContentStatusToString } from "../../utils/userContentStatusToString";
import { officialStatusToString } from "../../utils/officialStatusToString";

function ListElement({ elem }: { elem: Clinic }) {
  const manager = useManager()

  async function destroy() {
    if (window.confirm('本当に削除しますか？')) {
      const response = await authorizedRequest(manager, 'DestroyReport', { id: elem.id })
      if (response.status === 200) {
        window.location.reload()
      }
    }
  }

  return (
    <ElementInList>
      <span className='inline-block w-[48px]'>{elem.id}</span>
      <span className='flex flex-col items-end w-[94px]'>
        <span>{ toDateString(elem.applyDate)}</span>
        <span>{ toHourMinutesString(elem.applyDate) }</span>
      </span>
      <span className='inline-block ml-[12px] w-[120px] break-all'>{ elem.customer?.nicname }</span>
      <Link to={`/reports/${elem.id}`} className='inline-block ml-[12px] w-[440px] break-all list-link'>{ elem.clinicName }</Link>
      <span className='inline-block ml-[12px]'>{ elem.theme?.name }</span>
      <span className='flex-1' />
      <button className='small-btn red-btn' onClick={destroy}>削除</button>
    </ElementInList>
  )
}

export const ReportList: React.FunctionComponent = () => {
  const manager = useManager()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  async function fetch({ query, page, per }: { query: string|null, page: number|null, per: number|null}): Promise<[Clinic[], number]> {
    const response = await fetchData({ query, page, per})
    if (response.status !== 200) return [[], 0]

    const { data } = response
    return [data.reports, data.count]
  }

  async function csvDownload() {
    const query = searchParams.get('query')
    const response = await fetchData(({page: 1, per: 5000, query}))
    if (response.status !== 200) return

    const {reports} = response.data
    const headers = [
      'ID', 'テーマ', 'タイトル', 'ステータス',
      '投稿日時', '投稿者ID', '投稿者ニックネーム',
      'オフィシャルメンバーステータス', 'コメント',
      '商品1', '商品2', '商品3', '商品4',
      '商品5', '商品6', '商品7', '商品8',
    ]

    downloadCsv([
      headers,
      ...reports.map(report => [
        report.id, report.theme?.name, report.clinicName,
        userContentStatusToString(report.statusId),
        toDateTimeString(report.applyDate),
        report.customer?.id, report.customer?.nicname,
        officialStatusToString((report.customer?.officialStatusId)) ,report.comment,
        report.product1, report.product2, report.product3, report.product4,
        report.product5, report.product6, report.product7, report.product8,
      ])
    ], 'みんなの美容レポ一覧.csv')
  }

  async function fetchData({query, page, per}: { query: string | null, page: number | null, per: number | null }) {
    const statusIds = searchParams.get('status')?.split(',')?.map(id => Number(id))
    const from = searchParams.get('from')
    const till = searchParams.get('till')

    return await authorizedRequest(manager, 'IndexReports', {
      ...(statusIds && { statuses: statusIds as UserContentStatus[] }),
      ...(from && { applyDateFrom: from }),
      ...(till && { applyDateTill: till }),
      ...(query && { query }),
      ...(page && { page }),
      ...(per && { per }),
    })
  }

  return (
    <>
    <FetchListView<Clinic>
      title='みんなの美容レポ一覧'
      fetch={fetch}
      ListElemComp={ListElement}
      headerElement={<ReportModal csvDownload={csvDownload} />}
    />
    </>
  )
}
