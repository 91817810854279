import { FormEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Channel, Channels } from "../../../utils/channel";
import { Brand, Maker } from "../../../types/typescript-axios";
import { authorizedRequest } from "../../../utils/api-request";
import { SidebarLayout } from "../../layout/SidebarLayout";
import { LeftLabel } from "../../molecules/LeftLabel";
import { useManager } from "../../contexts/ManagerProvider";
import { BrandCreateFunction } from "../../../pages/brands/NewBrand";
import { BrandUpdateFunction } from "../../../pages/brands/BrandDetail";

const Akasatana = [
  'あ', 'か', 'さ', 'た', 'な',
  'は', 'ま', 'や', 'ら', 'わ',
]
type AkasatanaType = typeof Akasatana[number]

type Props = {
  create: BrandCreateFunction
} | {
  brand: Brand
  update: BrandUpdateFunction
}
export const BrandForm = (props: Props) => {
  const brand = 'brand' in props ? props.brand : undefined
  const manager = useManager()
  const navigate = useNavigate()
  const [name, setName] = useState<string>(brand?.name || '')
  const [subName, setSubName] = useState<string>(brand?.subName || '')
  const [akasatana, setAkasatana] = useState<AkasatanaType>(brand?.akasatana || 'あ')
  const [searchWord, setSearchWord] = useState<string>(brand?.searchWord || '')
  const [channel, setChannel] = useState<Channel>(brand?.channel || 'デパコス')
  const [topPageUrl, setTopPageUrl] = useState<string>(brand?.topPageUrl || '')
  const [purchaseUrl, setPurchaseUrl] = useState<string>(brand?.purchaseUrl || '')
  const [isPublished, setIsPublished] = useState<boolean>(brand?.isPublished || false)

  const [makers, setMakers] = useState<Maker[]>([])
  const [selectedMaker, setSelectedMaker] = useState<Maker|undefined>(brand?.maker)
  const [errorStatement, setErrorStatement] = useState<string>()
  const [searchMakerWord, setSearchMakerWord] = useState<string>()

  useEffect(() => {
    fetchMakers()
  }, [])

  async function fetchMakers() {
    const response = await authorizedRequest(manager, 'IndexMakers')
    if (response.status !== 200) return

    const { data } = response
    setMakers(data.makers)
  }

  async function submitForm(e: FormEvent) {
    e.preventDefault()
    if (!selectedMaker) {
      setErrorStatement('メーカーを選択してください')
      return
    }

    if ('brand' in props) {
      props.update({
        id: props.brand.id, name, subName, akasatana, channel, searchWord,
        topPageUrl, purchaseUrl, isPublished,
        makerId: selectedMaker.id,
      })
    } else {
      props.create({
        name, subName, akasatana, channel, searchWord,
        topPageUrl, purchaseUrl, isPublished,
        makerId: selectedMaker.id,
      })
    }
    navigate('/brands/list')
  }

  async function searchMaker() {
    const response = await authorizedRequest(manager, 'IndexMakers', {
      query: searchMakerWord,
    })
    if (response.status !== 200) return

    setMakers(response.data.makers)
    setSelectedMaker(undefined)
  }

  return (
    <SidebarLayout>
      <div className='py-4 px-4'>
        {
          brand ? (
            <h1>ブランド詳細</h1>
          ) : (
            <h1>ブランド新規作成</h1>
          )
        }

        <form onSubmit={submitForm}>
          <LeftLabel title='表示名' required={true}>
            <input value={name}
                   onChange={(e) => setName(e.target.value) }
                   required
            />
          </LeftLabel>
          <LeftLabel title='表示名（サブ）'>
            <input
              value={subName}
              onChange={(e) => setSubName(e.target.value)}
            />
          </LeftLabel>
          <LeftLabel title='あかさたな' required={true}>
            <select value={akasatana} onChange={(e) => setAkasatana(e.target.value)}>
              {
                Akasatana.map(w => (
                  <option>{ w }</option>
                ))
              }
            </select>
          </LeftLabel>
          <LeftLabel title='検索ワード'>
            <input
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
            />
          </LeftLabel>
          <LeftLabel title='チャネル' required={true}>
            <select value={channel} onChange={(e) => setChannel(e.target.value)}>
              {
                Channels.map(c => (
                  <option>{ c }</option>
                ))
              }
            </select>
          </LeftLabel>
          <LeftLabel title='公式URL'>
            <input
              value={topPageUrl}
              onChange={(e) => setTopPageUrl(e.target.value)}
            />
          </LeftLabel>
          <LeftLabel title='購入URL'>
            <input
              value={purchaseUrl}
              onChange={(e) => setPurchaseUrl(e.target.value)}
            />
          </LeftLabel>
          <LeftLabel title='表示する/表示しない' required={true}>
            <div>
              <input
                id='is-published'
                type='radio'
                className='w-[24px]'
                checked={isPublished}
                onChange={() => setIsPublished(true)}
              />
              <label htmlFor='is-published'>
                表示する
              </label>
              <span className='inline-block w-[24px]' />
              <input
                id='not-published'
                type='radio'
                className='w-[24px]'
                checked={!isPublished}
                onChange={() => setIsPublished(false)}
              />
              <label htmlFor='not-published'>
                表示しない
              </label>
            </div>
          </LeftLabel>

          <LeftLabel title='メーカー' required={true}>
            {
              selectedMaker && (
                <div>
                  { selectedMaker.name }
                </div>
              )
            }
          </LeftLabel>

          <div className='mt-[24px]'>
            <div className='flex items-center' onSubmit={searchMaker}>
              <span className='text-xl font-bold'>メーカーを検索</span>
              <div className='flex items-center ml-4'>
                <input
                  value={searchMakerWord}
                  onChange={(e) => setSearchMakerWord(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.code === 'Enter') {
                      e.preventDefault()
                      searchMaker()
                    }
                  }}
                />
                <button type='button' className='gray-btn h-[42px] w-[42px]' onClick={searchMaker}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path
                      d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                  </svg>
                </button>
              </div>
            </div>

            <div className='border border-gray-400 rounded h-[400px] overflow-scroll mt-4 mb-[40px]'>
              {
                makers.map((maker) => {
                  return (
                    <div
                      className={`py-4 px-8 border-b border-b-gray-200 hover:bg-gray-50 cursor-pointer ${maker.id === selectedMaker?.id && 'bg-blue-50'}`}
                      onClick={() => setSelectedMaker(maker)}
                    >
                      { maker.name }
                    </div>
                  )
                })

              }
            </div>
          </div>

          {
            errorStatement && (<div className='mb-4 text-red-500'>{ errorStatement }</div>)
          }
          {
            brand ? (
              <button>更新</button>
            ) : (
              <button>作成</button>
            )
          }
        </form>
      </div>
    </SidebarLayout>
  )
}
