import React, { ReactElement, useEffect, useState } from "react";
import { useManager } from "../contexts/ManagerProvider";
import { AutoFocusDiv } from "../shared/parts/AutoFocusDiv";
import { useSignOut, withLoginGuard } from "../shared/common/LoginGuard";
import { Link } from "react-router-dom";
import { ManagerAuthority } from "../../types/typescript-axios";

const Comp: React.FunctionComponent = ({children}) => {
  const manager = useManager()
  const signout = useSignOut()
  const [showSignout, setShowSignout] = useState<boolean>(false)
  const [sidebarContents, setSidebarContents] = useState<[ReactElement|string, string][]>([])

  useEffect(() => {
    if (!manager) return

    if (manager.authority === ManagerAuthority.Staff) {
      setSidebarContents([
        [<div>商品管理</div>, '/products/list'],
        [<div>オススメ商品</div>, '/products/recommends'],
        [<div>ランキング解析</div>, '/ranking-analysis'],
        [<div>会員管理</div>, '/customers/list'],
        [<div>クチコミ管理</div>, '/product-reviews/list'],
        [<div>みんなの美容レポ管理</div>, '/reports/list'],
        [<div>メーカー管理</div>, '/makers/list'],
        [<div>ブランド管理</div>, '/brands/list'],
        [<div>カテゴリ管理</div>, '/categories/list'],
        [<div>広告管理</div>, '/ads'],
        [<div>タグ管理</div>, '/tags'],
        [<div>プレゼント管理</div>, '/presents'],
        [<div>通知管理</div>, '/global-notifications'],
      ])
    } else if (manager.authority === ManagerAuthority.Guest) {
      setSidebarContents([
        [<div>商品管理</div>, '/products/list'],
      ])
    }else {
      setSidebarContents([
        [<div>商品管理</div>, '/products/list'],
        [<div>オススメ商品</div>, '/products/recommends'],
        [<div>ランキング解析</div>, '/ranking-analysis'],
        [<div>会員管理</div>, '/customers/list'],
        [<div>クチコミ管理</div>, '/product-reviews/list'],
        [<div>みんなの美容レポ管理</div>, '/reports/list'],
        [<div>メーカー管理</div>, '/makers/list'],
        [<div>ブランド管理</div>, '/brands/list'],
        [<div>カテゴリ管理</div>, '/categories/list'],
        [<div>管理者管理</div>, '/managers/list'],
        [<div>広告管理</div>, '/ads'],
        [<div>タグ管理</div>, '/tags'],
        [<div>プレゼント管理</div>, '/presents'],
        [<div>通知管理</div>, '/global-notifications'],
      ])
    }

  }, [manager])


  return (
    <div>
      <header className='flex justify-between items-center h-[78px] border-b border-b-gray-400 px-[30px]'>
        <div className='flex flex-col items-center'>
          <img src='/images/logo.png' alt='logo' className='h-[30px]'/>
          <span className='text-cosmebi-pink font-bold mt-[2px]'>管理画面</span>
        </div>
        <div
          className='relative flex items-center text-sm font-bold cursor-pointer'
          onClick={() => setShowSignout(true)}
        >
          {manager?.attributes?.email}
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/>
          </svg>
          {
            showSignout && (
              <AutoFocusDiv
                className='
                  flex justify-center items-center absolute top-[30px] right-0
                  bg-white h-[40px] w-[160px] border border-cosmebi-pink text-cosmebi-pink
                  hover:bg-cosmebi-pink hover:text-white
                  '
                onBlur={() => setShowSignout(false)}
                onClick={signout}
              >
                ログアウト
              </AutoFocusDiv>
            )
          }
        </div>
      </header>
      <div className='flex'>
        <nav style={{ height: 'calc(100vh - 78px)'}}
             className='w-[260px] pt-[24px]'
        >
          {
            sidebarContents.map(([comp, link], index) => (
              <Link
                key={index}
                to={link}
                className='
                  flex justify-center items-center w-full h-[60px]
                  font-bold hover:underline hover:decoration-cosmebi-pink
                  px-[26px]
                '
              >
                <div
                  className='
                    flex justify-center items-center h-full w-full border-b-[2px] border-b-gray-300 overflow-scroll
                    text-gray-700
                    '
                >
                  { comp }
                </div>
              </Link>
            ))
          }
        </nav>
        <main style={{ height: 'calc(100vh - 78px)'}} className='w-full overflow-scroll pt-24 pb-[60px] px-12'>
          {children}
        </main>
      </div>
    </div>
  )
}

export const SidebarLayout = withLoginGuard(Comp)
