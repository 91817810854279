export const IconCross = ({ className = '', onClick = () => {}}: { className?: string, onClick?: () => void }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360" className={'fill-current ' + className} onClick={onClick}>
    <path className="icon"
          d="M337.572,293.77L223.65,179.85L337.572,65.928c3.547-3.546,3.163-9.678-0.855-13.7l-29.095-29.093
          c-4.022-4.021-10.153-4.402-13.7-0.856L180,136.199L66.08,22.279c-3.547-3.547-9.681-3.165-13.702,0.856L23.286,52.228
          c-4.02,4.022-4.404,10.154-0.857,13.7L136.35,179.85L22.429,293.77c-3.547,3.545-3.163,9.678,0.857,13.701l29.093,29.092
          c4.021,4.023,10.155,4.406,13.702,0.857L180,223.499L293.92,337.42c3.548,3.549,9.679,3.166,13.701-0.857l29.095-29.092
          C340.735,303.448,341.121,297.315,337.572,293.77z"
    />
  </svg>
)