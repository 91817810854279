import { Dispatch, SetStateAction, useState } from "react";

// setObjectDataを利用する場合は、実際には型が保証されないので注意
export function useObjectState<S = undefined>(): readonly [S | undefined, (<X extends keyof S>(key: X, value: S[X]) => void), Dispatch<SetStateAction<S | undefined>>];
export function useObjectState<T>(initialData: T): readonly [T, (<X extends keyof T>(key: X, value: T[X]) => void), Dispatch<SetStateAction<T>>]
export function useObjectState<T>(initialData?: T) {
  // @ts-ignore
  const [data, setData] = useState<T>(initialData)

  function setObjectData<X extends keyof T>(key: X, value: T[X]) {
    return setData(obj => ({
      ...obj,
      [key]: value,
    }))
  }

  return [data, setObjectData, setData] as const
}
