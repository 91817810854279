import React, { FormEvent, ReactElement, useEffect, useState } from "react";
import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SelectWithType } from "../molecules/SelectWithType";
import { Pagination } from "../parts/Pagination";
import _ from 'lodash'

const PER_DEFAULT = 20
const PerSelectList: number[] = [20, 50, 100]

type Props<T> = {
  title: string
  fetch: (
    { query, page, per }:
      { query: string | null, page: number | null, per: number | null }
  ) => Promise<[T[], number]>
  ListElemComp: ({elem}: { elem: T }) => ReactElement<any, any> | null, create?: () => void
  headerElement?: ReactElement,
  hasSearch?: boolean
}
export function FetchListView<T>(
  {title, fetch, ListElemComp, create, headerElement, hasSearch = true}: Props<T>
) {
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const [list, setList] = useState<T[]>([])
  const [currentQuery, setCurrentQuery] = useState<string>('')
  const [currentPer, setCurrentPer] = useState<number>(PER_DEFAULT)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>()

  useEffect(() => {
    async function fetchDataCore() {
      const query = searchParams.get('query')
      const page = searchParams.get('page') ? Number(searchParams.get('page')) : null
      let per = searchParams.get('per') ? Number(searchParams.get('per')) : null
      if (!per || !PerSelectList.includes(per)) per = PER_DEFAULT // デフォルト値
      setCurrentQuery(query || '')
      setCurrentPage(page || 1)
      if (per) setCurrentPer(per)
      const [data, count] = await fetch({query, page, per})
      setList(data)
      setTotalCount(count)
    }

    const fetchData = _.debounce(fetchDataCore, 300, {leading: false, trailing: true})
    if (list.length === 0) {
      fetchDataCore() // 最初は待たずに実行する
    } else {
      fetchData()
    }
  }, [params, searchParams, list.length])

  function search(e: FormEvent) {
    e.preventDefault()

    searchParams.delete('page')

    if (!currentQuery) searchParams.delete('query')
    else searchParams.set('query', currentQuery)
    navigate({
      search: `?${searchParams}`
    })
  }

  function changePer(newPer: number) {
    searchParams.set('per', String(newPer))
    if (searchParams.get('page')) searchParams.delete('page')
    navigate({
      search: `?${searchParams}`
    })
  }

  return (
    <SidebarLayout>
      <div className='flex justify-between items-center py-4 px-4'>
        <h1>{title}</h1>
        <div>
          {
            hasSearch && (
              <form className='inline-flex' onSubmit={search}>
                <input placeholder='検索する' value={currentQuery} onChange={(e) => setCurrentQuery(e.target.value)}/>
                <button className='gray-btn h-[42px] w-[42px]'>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path
                      d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                  </svg>
                </button>
              </form>
            )
          }
          {
            create && (
              <button className='green-btn h-[42px] w-[120px] ml-4' onClick={create}>新規作成</button>
            )
          }
        </div>
      </div>
      <div className='flex justify-between items-center px-4 pb-4'>
        <div className='flex flex-1 items-center'>
          { headerElement }
          <p className='pl-3 text-xl font-semibold'>検索結果：{ totalCount }件が該当しました</p>
        </div>
        <div className='flex items-center'>
          <div className='flex flex-col items-center'>
            <SelectWithType<number>
              currentValue={currentPer}
              list={PerSelectList.map(p => [`${p}件`, p])}
              onSelect={changePer}
            />
            <div className='mt-1 text-xs font-semibold'>取得件数</div>
          </div>
          <span className='ml-4'></span>
          {
            currentPage !== undefined && currentPer !== undefined && totalCount !== undefined && (
              <Pagination page={currentPage} per={currentPer} count={totalCount} />
            )
          }
        </div>
      </div>
      <div className='px-4 flex flex-col gap-[16px] '>
        {
          list.map((elm, index) => <ListElemComp key={index} elem={elm}/>)
        }
      </div>
    </SidebarLayout>
  )
}
