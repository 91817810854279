import React, { useEffect, useState } from 'react';
import { useManager } from '../../components/contexts/ManagerProvider';
import { CustomerStatus, OfficialStatus, Pref } from "../../types/typescript-axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authorizedRequest } from "../../utils/api-request";
import { downloadCsv } from "../../lib/download-csv";
import { customerStatusToString } from "../../utils/customerStatusToString";
import { officialStatusToString } from "../../utils/officialStatusToString";

type Props = {
  csvDownload: () => void
}
export function CustomerModal({ csvDownload }: Props): JSX.Element {
  const [showModal, setShowModal] = useState<boolean>(false);
  const manager = useManager()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [customerStatuses, setCustomerStatuses] = useState<CustomerStatus[]>([])
  const [officialStatuses, setOfficialStatuses] = useState<OfficialStatus[]>([])
  const [birthdayFrom, setBirthdayFrom] = useState<string>()
  const [birthdayTill, setBirthdayTill] = useState<string>()
  const [prefId, setPrefId] = useState<number>()
  const [phoneNumber, setPhoneNumber] = useState<string>()
  const [twitter, setTwitter] = useState<string>()
  const [facebook, setFacebook] = useState<string>()
  const [instagtam, setInstagram] = useState<string>()
  const [createDateFrom, setCreateDateFrom] = useState<string>()
  const [createDateTill, setCreateDateTill] = useState<string>()
  const [updateDateFrom, setUpdateDateFrom] = useState<string>()
  const [updateDateTill, setUpdateDateTill] = useState<string>()
  const [prefs, setPrefs] = useState<Pref[]>([])

  useEffect(() => {
    async function fetchPrefs() {
      const response = await authorizedRequest(manager, 'IndexPrefs')
      if (response.status !== 200) return

      const { data: { prefs }} = response
      setPrefs(prefs)
    }
    fetchPrefs()
  }, [])

  useEffect(() => {
    setCustomerStatuses(searchParams.get('customer-statuses')?.split(',')?.map(s => Number(s) as CustomerStatus) || [])
    //setOfficialStatuses(searchParams.get('official-statuses')?.split(',')?.map(s => Number(s) as OfficialStatus) || [])
    setBirthdayFrom(searchParams.get('birthday-from') || undefined)
    setBirthdayTill(searchParams.get('birthday-till') || undefined)
    setPrefId(searchParams.get('pref') ? Number(searchParams.get('pref')) : undefined)
    setPhoneNumber(searchParams.get('phone') || undefined)
    setTwitter(searchParams.get('twitter') || undefined)
    setFacebook(searchParams.get('facebook') || undefined)
    setInstagram(searchParams.get('instagtam') || undefined)
    setCreateDateFrom(searchParams.get('create-from') || undefined)
    setCreateDateTill(searchParams.get('create-till') || undefined)
    setUpdateDateFrom(searchParams.get('update-from') || undefined)
    setUpdateDateTill(searchParams.get('update-till') || undefined)
  }, [searchParams])


  function toggleCustomerStatus(status: CustomerStatus) {
    if (customerStatuses.includes(status)) setCustomerStatuses(customerStatuses.filter(s => s !== status))
    else setCustomerStatuses([...customerStatuses, status])
  }
  function toggleOfficialStatus(status: OfficialStatus) {
    if (officialStatuses.includes(status)) setOfficialStatuses(officialStatuses.filter(s => s !== status))
    else setOfficialStatuses([...officialStatuses, status])
  }

  function search() {
    searchParams.delete('page')

    customerStatuses?.length > 0 ? searchParams.set('customer-statuses', customerStatuses.join(',')) : searchParams.delete('customer-statuses')
    officialStatuses?.length > 0 ? searchParams.set('official-statuses', officialStatuses.join(',')) : searchParams.delete('official-statuses')

    birthdayFrom ? searchParams.set('birthday-from', birthdayFrom) : searchParams.delete('birthday-from')
    birthdayTill ? searchParams.set('birthday-till', birthdayTill) : searchParams.delete('birthday-till')
    prefId ? searchParams.set('pref', String(prefId)) : searchParams.delete('pref')
    phoneNumber ? searchParams.set('phone', phoneNumber) : searchParams.delete('phone')
    twitter ? searchParams.set('twitter', twitter) : searchParams.delete('twitter')
    facebook ? searchParams.set('facebook', facebook) : searchParams.delete('facebook')
    instagtam ? searchParams.set('instagtam', instagtam) : searchParams.delete('instagtam')
    createDateFrom ? searchParams.set('create-from', createDateFrom) : searchParams.delete('create-from')
    createDateTill ? searchParams.set('create-till', createDateTill) : searchParams.delete('create-till')
    updateDateFrom ? searchParams.set('update-from', updateDateFrom) : searchParams.delete('update-from')
    updateDateTill ? searchParams.set('update-till', updateDateTill) : searchParams.delete('update-till')

    navigate({
      search: `?${searchParams}`
    })
    setShowModal(false)
  }

  return (
    <>
      { manager.personalDataFlg && (
        <button
          className='blue-btn w-[150px] h-[45px] mr-8'
          onClick={csvDownload}
        >
          CSVダウンロード
        </button>
      )}
      <button onClick={() => setShowModal(true)} className='w-[100px] h-[45px] bg-blue-400 duration-200'>
        詳細検索
      </button>
      {
        showModal && (
          <div
            className='fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black-transparent z-50'>
            <div className='flex flex-col items-center w-[800px] max-h-[80vh] bg-white py-40 overflow-scroll'>
              <div className='w-[680px]'>

                <div className="mt-3 text-center">
                  <h1
                    className=" font-medium text-blue-400"
                    id="modal-title">
                    会員一覧詳細検索
                  </h1>
                </div>
                <div className='mb-[15px] mx-[20px] flex'>
                  <p className='mb-[10px] text-[15px] w-[170px]'>会員種別</p>
                  <div className='flex items-center mb-[25px]'>
                    {
                      ([['仮会員', 1], ['本会員', 2], ['退会', 3]] as const).map(([customerStatus, id]) => (
                        <div className='flex items-center mr-[25px]'>
                          <input
                            id={`customer-status-${id}`} type="checkbox" className='w-[15px]'
                            value={id} checked={customerStatuses.includes(id)}
                            onChange={(e) => toggleCustomerStatus(Number(e.target.value) as CustomerStatus)}
                          />
                          <label htmlFor={`customer-status-${id}`} className='text-[15px] ml-[8px] w-[60px]'>{ customerStatus }</label>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className='mb-[15px] mx-[20px] flex'>
                  <p className='mb-[10px] text-[15px] w-[170px]'>オフィシャル</p>
                  <div className='flex justify-start flex-wrap gap-y-5 mb-[25px] w-[75%]'>
                    {
                      ([
                        ['一般会員', 1], ['オフィシャルメンバー招待中', 2], ['オフィシャルメンバー', 3],
                        ['アンバサダー招待中', 4], ['アンバサダー', 5]
                      ] as const).map(([status, id]) => (
                        <div className='flex items-center mr-[25px]'>
                          <input
                            id={`official-status-${id}`} type="checkbox" className='w-[15px]'
                            value={id} checked={officialStatuses.includes(id)}
                            onChange={(e) => toggleOfficialStatus(Number(e.target.value) as OfficialStatus)}
                          />
                          <label htmlFor={`official-status-${id}`} className='text-[15px] ml-[8px] w-[120px]'>{ status }</label>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className='mb-[25px] mx-[20px] flex items-center'>
                  <label className='text-[15px] mb-[5px] w-[170px]'>誕生日</label>
                  <input
                    type="date" className='w-[168px] mr-[10px] h-[35px] shadow-sm' value={birthdayFrom}
                    onChange={(e) => setBirthdayFrom(e.target.value)}
                  />
                  <span className=''>〜</span>
                  <input
                    type="date" className='w-[168px] ml-[10px] h-[35px] shadow-sm' value={birthdayTill}
                    onChange={(e) => setBirthdayTill(e.target.value)}
                  />
                </div>
                <div className='flex items-center mb-[15px] mx-[20px]'>
                  <label className='text-[15px] mb-[5px] w-[170px]'>都道府県</label>
                  <select className='w-[370px] h-[35px] shadow-sm mb-[15px]' value={prefId}
                          onChange={(e) => setPrefId(Number(e.target.value))}>
                    <option>都道府県を選択</option>
                    {
                      prefs.map((pref) => (
                        <option key={pref.id} value={pref.id}>{ pref.name }</option>
                      ))
                    }
                  </select>
                </div>
                <div className='mb-[5px] mx-[20px] flex'>
                  <p className='text-[15px] w-[170px]'>電話番号</p>
                  <div className='flex items-center mb-[25px]'>
                    <input
                      className='h-[35px] w-[370px] shadow-sm' value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className='mb-[5px] mx-[20px] flex'>
                  <p className='text-[15px] w-[170px]'>Twitterアカウント</p>
                  <div className='flex items-center mb-[25px]'>
                    <input
                      className='h-[35px] w-[370px] shadow-sm' value={twitter}
                      onChange={(e) => setTwitter(e.target.value)}
                    />
                  </div>
                </div>
                <div className='mb-[5px] mx-[20px] flex'>
                  <p className='text-[15px] w-[170px]'>Facebookアカウント</p>
                  <div className='flex items-center mb-[25px]'>
                    <input
                      className='h-[35px] w-[370px] shadow-sm' value={facebook}
                      onChange={(e) => setFacebook(e.target.value)}
                    />
                  </div>
                </div>
                <div className='mb-[5px] mx-[20px] flex'>
                  <p className='text-[15px] w-[170px]'>Instagramアカウント</p>
                  <div className='flex items-center mb-[25px]'>
                    <input
                      className='h-[35px] w-[370px] shadow-sm' value={instagtam}
                      onChange={(e) => setInstagram(e.target.value)}
                    />
                  </div>
                </div>
                <div className='mb-[25px] mx-[20px] flex items-center'>
                  <label className='text-[15px] mb-[5px] w-[170px]'>投稿日</label>
                  <input
                    type="date" className='w-[180px] mr-[10px] h-[35px] shadow-sm' value={createDateFrom}
                    onChange={(e) => setCreateDateFrom(e.target.value)}
                  />
                  <span className=''>〜</span>
                  <input
                    type="date" className='w-[180px] ml-[10px] h-[35px] shadow-sm' value={createDateTill}
                    onChange={(e) => setCreateDateTill(e.target.value)}
                  />
                </div>
                <div className='mb-[25px] mx-[20px] flex items-center'>
                  <label className='text-[15px] mb-[5px] w-[170px]'>更新日</label>
                  <input
                    type="date" className='w-[180px] mr-[10px] h-[35px] shadow-sm' value={updateDateFrom}
                    onChange={(e) => setUpdateDateFrom(e.target.value)}
                  />
                  <span className=''>〜</span>
                  <input
                    type="date" className='w-[180px] ml-[10px] h-[35px] shadow-sm' value={updateDateTill}
                    onChange={(e) => setUpdateDateTill(e.target.value)}
                  />
                </div>
                <div className="px-4 py-1 flex justify-center">
                  <button
                    type="button"
                    onClick={search}
                    className="mt-3 w-[200px] h-[45px] inline-flex justify-center rounded-[30px] bg-blue-400 shadow-sm px-4 py-2 text-white hover:bg-blue-500 font-bold duration-300 mr-[20px]">
                    この条件で検索する
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-[200px] h-[45px] inline-flex justify-center rounded-[30px] border border-blue-400 shadow-sm px-4 py-2 text-blue-400 hover:bg-blue-400 hover:text-white font-bold duration-300"
                    onClick={() => setShowModal(false)}>
                    戻る
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}
