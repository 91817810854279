import { useParams } from "react-router-dom";
import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { useEffect, useState } from "react";
import { Customer, Interest, Pref, Skin } from "../../types/typescript-axios";
import { useManager } from "../../components/contexts/ManagerProvider";
import { CustomerForm, CustomerFormData } from "../../components/templates/customers/CustomerForm";
import { authorizedRequest } from "../../utils/api-request";

export const CustomerDetail = () => {
  const manager = useManager()
  const { id } = useParams()
  const [customer, setCustomer] = useState<Customer>()
  const [prefList, setPrefList] = useState<Pref[]>()
  const [skinList, setSkinList] = useState<Skin[]>()
  const [interestList, setInterestList] = useState<Interest[]>()

  useEffect(() => {
    async function fetchCustomer() {
      if (!id) return
      const response = await authorizedRequest(manager, 'ShowCustomer', { id: +id })
      if (response.status !== 200) return

      const { data: { customer } } = response
      setCustomer(customer)
    }
    async function fetchPrefs() {
      const response = await authorizedRequest(manager, 'IndexPrefs')
      if (response.status !== 200) return

      const { data: { prefs }} = response
      setPrefList(prefs)
    }
    async function fetchSkins() {
      const response = await authorizedRequest(manager, 'IndexSkins')
      if (response.status !== 200) return

      const { data: { skins }} = response
      setSkinList(skins)
    }
    async function fetchInterests() {
      const response = await authorizedRequest(manager, 'IndexInterests')
      if (response.status !== 200) return

      const { data: { interests }} = response
      setInterestList(interests)
    }
    fetchPrefs()
    fetchSkins()
    fetchInterests()
    fetchCustomer()
  }, [])

  async function submit(data: Partial<CustomerFormData>) {
    if (!customer) return

    const response = await authorizedRequest(manager, 'UpdateCustomer', {
      id: customer.id,
      ...data,
    })
    if (response.status === 200){
      window.location.reload()
    } else {
      return response.data.params
    }
  }

  return (
    <SidebarLayout>
      {
        customer && (
          <CustomerForm customer={customer} prefList={prefList} skinList={skinList} interestList={interestList} update={submit} />
        )
      }
    </SidebarLayout>
  )
}
