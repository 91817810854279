import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { useManager } from "../../components/contexts/ManagerProvider";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Product } from "../../types/typescript-axios";
import { ProductForm, ProductFormData } from "../../components/templates/products/ProductForm";
import { authorizedRequest } from "../../utils/api-request";

export const ProductDetail = () => {
  const manager = useManager()
  const {id} = useParams()
  const [product, setProduct] = useState<Product>()
  console.log(product)

  useEffect(() => {
    async function fetchProduct() {
      if (!id) return

      const response = await authorizedRequest(manager, 'ShowProduct', {id: +id})
      if (response.status !== 200) return

      const {data: {product}} = response
      setProduct(product)
      console.log('product!')
      console.log(product)
    }
    fetchProduct()
  }, [])

  async function submit(data: ProductFormData) {
    if (!product) return

    await authorizedRequest(manager, 'UpdateProduct', {
      id: product.id,
      ...data,
    })
    window.location.reload()
  }

  return (
    <SidebarLayout>
      <h1>商品詳細</h1>
      {
        product && (
          <ProductForm product={product} submit={submit} />
        )
      }
    </SidebarLayout>
  )
}
