import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { useManager } from "../../components/contexts/ManagerProvider";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { QaAnswer, UserContentStatus } from "../../types/typescript-axios";
import { LeftLabel } from "../../components/molecules/LeftLabel";
import moment from "moment";
import { authorizedRequest } from "../../utils/api-request";

export const QaAnswerDetail = () => {
  const manager = useManager()
  const { id } = useParams()
  const [qaAnswer, setQaAnswer] = useState<QaAnswer>()
  const [status, setStatus] = useState<number>(1)
  const [nickname, setNickname] = useState<string>('')
  const [comment, setComment] = useState<string>('')

  useEffect(() => {
    async function fetchQaAnswer() {
      if (!id) return

      const response = await authorizedRequest(manager, 'ShowQaAnswer', { id: +id })
      if (response.status !== 200) return

      const { data: { qaAnswer }} = response

      console.log(qaAnswer)
      setQaAnswer(qaAnswer)
      setStatus(qaAnswer.statusId);
      setNickname(qaAnswer.nicname); setComment(qaAnswer.comment);
    }
    fetchQaAnswer()
  }, [])

  async function updateQa() {
    if (!id) return

    await authorizedRequest(manager, 'UpdateQaAnswer', {
      id: +id, comment,
      nicname: nickname,
      statusId: status as UserContentStatus,
    })
    window.location.reload()
  }

  return (
    <SidebarLayout>
      <h1>Q&A詳細</h1>
      <div>
        <LeftLabel title='ID'>{qaAnswer?.id}</LeftLabel>
        <LeftLabel title='作成日時'>{qaAnswer && moment(qaAnswer?.createDate).format('YYYY-MM-DD HH:mm')}</LeftLabel>
        <LeftLabel title='ステータス'>
          <select value={status} onChange={(e) => setStatus(+e.target.value)}>
            <option value={1}>公開</option>
            <option value={2}>非公開</option>
            <option value={8}>下書き</option>
          </select>
        </LeftLabel>
        <LeftLabel title='ニックネーム'>
          <input value={nickname} onChange={(e) => setNickname(e.target.value)} />
        </LeftLabel>
        <LeftLabel title='コメント'>
          <textarea value={comment} onChange={(e) => setComment(e.target.value)} />
        </LeftLabel>

        <div className='flex justify-center mt-16'>
          <button onClick={updateQa}>登録</button>
        </div>
      </div>
    </SidebarLayout>
  )
}
