import React, { useContext, useEffect } from "react";
import { ManagerContext } from "../../contexts/ManagerProvider";
import { useNavigate } from "react-router-dom";

const LoginGuardContext = React.createContext<() => void>(() => {
})

export function withLoginGuard(Component: React.ComponentType): React.FunctionComponent {
  return (props) => {
    const [manager, setManager, signOut] = useContext(ManagerContext)

    const navigate = useNavigate()

    useEffect(() => {
      console.log(manager)
      if (!manager) {
        navigate('/login')
      } else {
        if (manager.challengeName === 'NEW_PASSWORD_REQUIRED') {
          navigate('/auth/new-password-required')
        }

        if (!manager.attributes) {
          manager.getUserAttributes((err, result) => {
            console.log('getUserAttributes')
            console.log(err)
            console.log(result)
            if (!result) return

            const attributes = result?.reduce((prev, cur) => {
              const name = cur.getName()
              if (name === 'sub') prev.sub = cur.getValue()
              if (name === 'email') prev.email = cur.getValue()
              return prev
            }, {sub: '', email: ''})
            manager.attributes = attributes
            setManager(manager)
          })
        }
      }
    }, [manager, navigate])

    async function signOutAndNavigateToLogin() {
      await signOut()
      navigate('/login')
    }

    if (!manager || !manager.isApiChecked) return <div/>

    return (
      <LoginGuardContext.Provider value={signOutAndNavigateToLogin}>
        <Component {...props} />
      </LoginGuardContext.Provider>
    )
  }
}

export function useSignOut(): () => void {
  return useContext(LoginGuardContext)
}
