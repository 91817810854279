import { FetchListView } from "../../components/templates/FetchListView";
import { PresentCampaign, Tag } from "../../types/typescript-axios";
import { authorizedRequest } from "../../utils/api-request";
import { useManager } from "../../components/contexts/ManagerProvider";
import { ElementInList } from "../../components/parts/ElementInList";
import React, {useState} from "react";
import { downloadCsv } from "../../lib/download-csv";
import { officialStatusToString } from "../../utils/officialStatusToString";
import { useParams } from "react-router-dom";

function ListElement({elem}: { elem: PresentCampaign }) {
  const manager = useManager()
  async function download() {
    const response = await authorizedRequest(manager, 'IndexPresentHistories', {
      postId: elem.postId,
    })
    if (response.status !== 200) return
    if (manager?.attributes?.email) {
      const email = manager.attributes.email
      const actionType = 'プレゼント管理CSV'
      await authorizedRequest(manager, 'CreateCsvLogs', {
        email,
        actionType
      })
    }

    const { presentHistories } = response.data
    downloadCsv(presentHistories.map(history => [
      history.postId, history.present, history.email, officialStatusToString(history.customer.officialStatusId),
      history.brand, history.periodS, history.periodE,
      history.postUrl, history.thumbUrl,
      history.name01, history.name02, history.kana01, history.kana02,
      history.postalCode, history.pref?.name, history.addr01, history.addr02,
      history.phoneNumber, history.contents,
      history.createDate, history.updateDate,
      history.customerBirth, history.customerSkins, history.customerInterests,
    ]), `${elem.title}.csv`)
  }

  return (
    <ElementInList>
      <span className='inline-block w-[700px]'>{elem.title}</span>
      <span className='inline-block w-[100px]'>{elem.periodStart}</span>
      <span className='inline-block w-[100px]'>{elem.periodEnd}</span>
      <div className='flex-1' />
      { manager.personalDataFlg && (
        <button
          className='blue-btn'
          onClick={download}
        >
          CSVダウンロード
        </button>
      )}
    </ElementInList>
  )
}

export const PresentList = () => {
  const manager = useManager()

  async function fetch({
                         query,
                         page,
                         per
                       }: { query: string | null, page: number | null, per: number | null }): Promise<[PresentCampaign[], number]> {
    const response = await authorizedRequest(manager, 'IndexPresentCampaigns', {
      ...(query && {query}),
      ...(page && {page}),
      ...(per && {per}),
    })
    if (response.status !== 200) return [[], 0]

    const { data } = response
    return [data.presentCampaigns, data.count]
  }

  return (
    <>
      <FetchListView<PresentCampaign>
        title='プレゼント管理'
        fetch={fetch}
        ListElemComp={ListElement}
        hasSearch={false}
      />
    </>
  )
}
