import React, { FormEvent, useState } from "react";
import { FetchListView } from "../../components/templates/FetchListView";
import { useManager } from "../../components/contexts/ManagerProvider";
import { ElementInList } from "../../components/parts/ElementInList";
import { ConfirmModal } from "../../components/parts/ConfirmModal";
import { Maker } from "../../types/typescript-axios";
import { authorizedRequest } from "../../utils/api-request";
import { downloadCsv } from "../../lib/download-csv";
import { userContentStatusToString } from "../../utils/userContentStatusToString";
import { toDateTimeString } from "../../utils/toDateTimeString";
import { useSearchParams } from "react-router-dom";

function ListElement({elem}: { elem: Maker }) {
  const manager = useManager()

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [newName, setNewName] = useState<string>(elem.name)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false)

  async function submit() {
    await authorizedRequest(manager, 'UpdateMaker', {
      id: elem.id,
      name: newName,
    })
    elem.name = newName
    setIsEdit(false)
  }

  async function destroy() {
    const response = await authorizedRequest(manager, 'DestroyMaker', {
      id: elem.id,
    })
    if (response.status === 200) {
      window.location.reload()
    } else {
      alert('メーカーを削除できませんでした。')
      setShowConfirmDeleteModal(false)
    }
  }

  return (
    <ElementInList>
      <div className='flex flex-1 items-center justify-between'>
        <div className='flex items-center'>
          <span className='mr-4'>{elem.id}</span>
          {
            isEdit ? (
              <span className='flex items-center'>
              <input value={newName} onChange={(e) => setNewName(e.target.value)}/>
              <button className='small-btn ml-2' onClick={submit}>決定</button>
              <button className='gray-btn small-btn ml-4' onClick={() => setIsEdit(false)}>キャンセル</button>
            </span>
            ) : (
              <span>{elem.name}</span>
            )
          }
        </div>
        <div>
          <button className='gray h-[42px] w-[68px]' onClick={() => setIsEdit(true)}>編集</button>
          <button className='small-btn red-btn ml-4' onClick={() => setShowConfirmDeleteModal(true)}>削除</button>
        </div>
      </div>
      {
        showConfirmDeleteModal && (
          <ConfirmModal
            title='メーカーの削除'
            content='削除したら戻すことはできません。本当に削除しますか？'
            okLogic={destroy}
            cancelLogic={() => setShowConfirmDeleteModal(false)}
          />
        )
      }
    </ElementInList>
  )
}

export const MakerList: React.FunctionComponent = () => {
  const manager = useManager()
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [newMakerName, setNewMakerName] = useState<string>()
  const [searchParams] = useSearchParams()

  async function fetch({
                         query,
                         page,
                         per
                       }: { query: string | null, page: number | null, per: number | null }): Promise<[Maker[], number]> {
    const response = await fetchData({ query, page, per })
    if (response.status !== 200) return [[], 0]

    const { data } = response
    return [data.makers, data.count]
  }

  async function submitCreate(e: FormEvent) {
    e.preventDefault()
    if (!newMakerName) return

    await authorizedRequest(manager, 'CreateMaker', {
      name: newMakerName,
    })
    window.location.reload()
  }

  async function csvDownload() {
    const query = searchParams.get('query')
    const response = await fetchData(({page: 1, per: 5000, query}))
    if (response.status !== 200) return

    const {makers} = response.data
    const headers = [
      'ID', '名前',
    ]

    downloadCsv([
      headers,
      ...makers.map(maker => [
        maker.id, maker.name,
      ])
    ], 'メーカー一覧.csv')
  }

  async function fetchData({query, page, per}: { query: string | null, page: number | null, per: number | null }) {
    return await authorizedRequest(manager, 'IndexMakers', {
      ...(query && {query}),
      ...(page && {page}),
      ...(per && {per}),
    })
  }

  return (
    <>
      <FetchListView<Maker>
        title='メーカー管理'
        fetch={fetch}
        ListElemComp={ListElement}
        headerElement={<HeaderElement csvDownload={csvDownload} />}
        create={() => setShowCreateModal(true)}
      />
      {
        showCreateModal && (
          <div
            className='absolute top-0 left-0 flex items-center justify-center w-screen h-screen'
            style={{backgroundColor: 'rgba(0, 0, 0, .5)'}}
            onClick={() => setShowCreateModal(false)}
          >
            <div className='flex flex-col bg-white p-10 rounded-xl' onClick={(e) => {
              e.stopPropagation()
            }}>
              <h2>メーカーの新規作成</h2>
              <form onSubmit={submitCreate}>
                <input placeholder='名前を入力' value={newMakerName} onChange={(e) => setNewMakerName(e.target.value)}/>
                <button className='ml-4 small-btn'>作成</button>
              </form>
            </div>
          </div>
        )
      }
    </>
  )
}

type Props = {
  csvDownload: () => void
}
export const HeaderElement = ({ csvDownload }: Props) => {
  return (
    <button
      className='blue-btn w-[150px] h-[45px] mr-8'
      onClick={csvDownload}
    >
      CSVダウンロード
    </button>
  )
}
