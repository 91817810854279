import React, { useState } from "react";
import { FetchListView } from "../../components/templates/FetchListView";
import { useManager } from "../../components/contexts/ManagerProvider";
import { ElementInList } from "../../components/parts/ElementInList";
import { translateManagerAuthority } from "../../translations/translate-manager-authority";
import { ConfirmModal } from "../../components/parts/ConfirmModal";
import { Manager } from "../../types/typescript-axios";
import { Link, useNavigate } from "react-router-dom";
import { authorizedRequest } from "../../utils/api-request";

function ListElement({ elem }: { elem: Manager }) {
  const manager = useManager()
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false)

  async function destroy() {
    await authorizedRequest(manager, 'DestroyManager', {
      id: elem.id,
    })
    window.location.reload()
  }

  return (
    <ElementInList>
      <div className='flex flex-1 items-center justify-between'>
        <div className='flex'>
          <span className='w-[48px]'>{elem.id}</span>
          <span className='w-[340px]'>{elem.email}</span>
          <span className='w-[160px]'>{elem.personalDataFlg ? "個人情報取得◯" : "個人情報取得×"}</span>
          <span>{translateManagerAuthority(elem.authority)}</span>
        </div>
        <div className='flex gap-8'>
          <Link to={`/managers/${elem.id}`}>
            <button className='small-btn'>編集</button>
          </Link>
          <button className='small-btn red-btn' onClick={() => setShowConfirmDeleteModal(true)}>削除</button>
        </div>
      </div>
      {
        showConfirmDeleteModal && (
          <ConfirmModal
            title='会員の削除'
            content='削除したら戻すことはできません。本当に削除しますか？'
            okLogic={destroy}
            cancelLogic={() => setShowConfirmDeleteModal(false)}
          />
        )
      }
    </ElementInList>
  )
}

export const ManagerList: React.FunctionComponent = () => {
  const manager = useManager()
  const navigate = useNavigate()

  async function fetch({ query, page, per }: { query: string|null, page: number|null, per: number|null}): Promise<[Manager[], number]> {
    const response = await authorizedRequest(manager, 'IndexManagers', {
      ...(query && { query }),
      ...(page && { page }),
      ...(per && { per }),
    })
    if (response.status !== 200) return [[], 0]

    const { data } = response
    return [data.managers, data.count]
  }

  function create() {
    navigate('/managers/new')
  }

  return (
    <FetchListView
      title='管理者一覧'
      fetch={fetch}
      create={create}
      ListElemComp={ListElement}
    />
  )
}
