import React, { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";
import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { Product, ProductStatus } from "../../types/typescript-axios";
import { toDateString } from "../../utils/toDateString";
import { LeftLabel } from "../../components/molecules/LeftLabel";
import { useManager } from "../../components/contexts/ManagerProvider";
import _ from "lodash";
import { CognitoUserWithChallenge } from "../../config/cognito-authenticator";
import { useNavigate } from "react-router-dom";
import { authorizedRequest } from "../../utils/api-request";

const fetchProducts = _.debounce(async (manager: CognitoUserWithChallenge, query: string, setFunc: Dispatch<SetStateAction<Product[] | undefined>>) => {
  const response = await authorizedRequest(manager, 'IndexProducts', {
    ...(query && {query}),
    statuses: [ProductStatus.NUMBER_1],
  })
  if (response.status !== 200) return

  const {data: {products}} = response
  setFunc(products)
}, 300, {leading: false, trailing: true})

export const NewRecommend: React.FunctionComponent = () => {
  const manager = useManager()
  const navigate = useNavigate()
  const [product, setProduct] = useState<Product>()
  const [applyDate, setApplyDate] = useState<string>(toDateString(new Date().toISOString()))

  const [productList, setProductList] = useState<Product[]>()
  const [productSearchQuery, setProductSearchQuery] = useState<string>('')
  const [errorStatement, setErrorStatement] = useState<string>('')

  useEffect(() => {
    fetchProducts(manager, productSearchQuery, setProductList)
    setProduct(undefined)
  }, [productSearchQuery])

  async function submit(e: FormEvent) {
    e.preventDefault()
    if (!product) return setErrorStatement('商品を選択してください')

    await authorizedRequest(manager, 'CreateProductRecommend', {
      productId: product.id,
      applyDate,
    })
    navigate('/products/recommends')
  }

  return (
    <SidebarLayout>
      <h1>オススメ商品作成</h1>
      <form onSubmit={submit}>
        <LeftLabel title='公開日'>
          <input
            required
            type='date'
            value={applyDate}
            onChange={(e) => setApplyDate(e.target.value)}
          />
        </LeftLabel>
        <LeftLabel title='商品'>
          <input
            value={productSearchQuery}
            onChange={(e) => setProductSearchQuery(e.target.value)}
          />
          <div className='mt-16 mr-40 py-12 border border-gray-500 overflow-scroll h-[600px]'>
            {
              productList?.map(p => (
                <div
                  className={[
                    'flex items-center px-28 h-[48px] cursor-pointer hover:bg-gray-100',
                    p.id === product?.id && 'bg-blue-50'
                  ].join(' ')}
                  key={p.id}
                  onClick={() => setProduct(p)}
                >
                  <span className='flex w-[100px]'>{p.id}</span>
                  <span>{p.name}</span>
                </div>
              ))
            }
          </div>
        </LeftLabel>
        {
          errorStatement && (
            <p className='flex justify-center text-red-500 pb-28'>{ errorStatement }</p>
          )
        }
        <div className='flex justify-center'>
          <button>登録</button>
        </div>
      </form>
    </SidebarLayout>
  )
}
