import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const Pagination = ({page, per, count}: { page: number, per: number, count: number }) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const pageCount = Math.ceil(count / per)

  function changePage(newPage: number) {
    searchParams.set('page', String(newPage))
    navigate({
      search: `?${searchParams}`
    })
  }

  return (
    <div className='flex flex-col items-center'>
      <div className='flex items-center'>
        {
          page > 1 ? (
            <span
              className='flex items-center justify-center h-[40px] w-[40px] border border-gray-800 rounded cursor-pointer'
              onClick={() => changePage(page - 1)}
            >
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                 width="24px" fill="#000000"><rect fill="none" height="24" width="24"/><g><polygon
              points="17.77,3.77 16,2 6,12 16,22 17.77,20.23 9.54,12"/></g></svg>
          </span>
          ) : <span className='h-[40px] w-[40px] rounded'/>
        }
        <span className='flex justify-center items-center font-bold text-xl w-[48px]'>{page}</span>
        {
          page < pageCount ? (
            <span
              className='flex items-center justify-center h-[40px] w-[40px] border border-gray-800 rounded cursor-pointer'
              onClick={() => changePage(page + 1)}
            >
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                 width="24px" fill="#000000"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><polygon
              points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12"/></g></svg>
          </span>
          ) : <span className='h-[40px] w-[40px]'/>
        }
      </div>
      <div className='mt-1 text-xs font-semibold'>ページ</div>
    </div>
  )
}