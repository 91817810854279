import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { useParams } from "react-router-dom";
import { useManager } from "../../components/contexts/ManagerProvider";
import { useEffect, useState } from "react";
import { ProductReview, RecommendLevel } from "../../types/typescript-axios";
import { LeftLabel } from "../../components/molecules/LeftLabel";
import moment from "moment";
import { authorizedRequest } from "../../utils/api-request";

export function ProductReviewDetail() {
  const manager = useManager()
  const { id } = useParams()
  const [review, setReview] = useState<ProductReview>()
  const [reviewerName, setReviewerName] = useState<string>('')
  const [recommendLevel, setRecommendLevel] = useState<number>(0)
  const [title, setTitle] = useState<string>('')
  const [comment, setComment] = useState<string>('')

  useEffect(() => {
    async function fetchReview() {
      if (!id) return

      const response = await authorizedRequest(manager, 'ShowProductReview', { id: +id })
      if (response.status !== 200) return

      const { data: { productReview }} = response
      
      setReview(productReview)
      setReviewerName(productReview.reviewerName); setRecommendLevel(productReview.recommendLevel);
      setTitle(productReview.title); setComment(productReview.comment);
    }
    fetchReview()
  }, [])

  async function updateReview() {
    if (!id) return

    await authorizedRequest(manager, 'UpdateProductReview', {
      id: +id, title, comment, reviewerName, recommendLevel: recommendLevel as RecommendLevel
    })
    window.location.reload()
  }

  return (
    <SidebarLayout>
      <div className='py-4 px-4'>
        <h1>クチコミ詳細</h1>
        <div>
          <LeftLabel title='商品名'>{ review?.product?.name }</LeftLabel>
          <LeftLabel title='投稿日時'>{ review && moment(review?.createDate).format('YYYY-MM-DD HH:mm') }</LeftLabel>
          <LeftLabel title='投稿者'>
            <input value={reviewerName} onChange={(e) => setReviewerName(e.target.value)} />
          </LeftLabel>
          <LeftLabel title='おすすめ評価'>
            <select value={recommendLevel} onChange={(e) => setRecommendLevel(+e.target.value)}>
              <option value={1}>★</option>
              <option value={2}>★★</option>
              <option value={3}>★★★</option>
              <option value={4}>★★★★</option>
              <option value={5}>★★★★★</option>
            </select>
          </LeftLabel>
          <LeftLabel title='タイトル'>
            <input className='w-[540px]' value={title} onChange={(e) => setTitle(e.target.value)} />
          </LeftLabel>
          <LeftLabel title='コメント'>
            <textarea value={comment} onChange={(e) => setComment(e.target.value)} />
          </LeftLabel>
          <LeftLabel title='画像'>
            <div className='flex flex-wrap gap-8'>
              {review?.image1Url && <img src={ review?.image1Url } className='w-[170px] h-[170px]'/>}
              {review?.image2Url && <img src={ review?.image2Url } className='w-[170px] h-[170px]'/>}
              {review?.image3Url && <img src={ review?.image3Url } className='w-[170px] h-[170px]'/>}
            </div>
          </LeftLabel>
          <div className='flex justify-center'>
            <button onClick={updateReview}>登録</button>
          </div>
        </div>
      </div>
    </SidebarLayout>
  )
}
