import { ChangeEvent } from "react";
import { ImageWithCoverLayout } from '../molecules/ImageWithCoverLayout'
import { CustomerIcons } from "../../models/customer/customer-icons";

type Props = {
  image: string|undefined
  setImage: (image: string|undefined) => void
  chooseIndex: number|undefined
  setChooseIndex: (index: number) => void
}
export const ChooseMyimage = ({ image, setImage, chooseIndex, setChooseIndex }: Props) => {
  function readFile(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target?.files && e.target.files[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result as string)
      setChooseIndex(0)
    }
    reader.onerror = (error) => {
      console.log(error)
    }
    reader.readAsDataURL(file);
  }

  return (
    <div className='flex flex-wrap gap-x-[42px] gap-y-[24px]'>
      {
        image ? (
          <div onClick={() => setImage(undefined)} className='cursor-pointer'>
            <ImageWithCoverLayout
              src={image}
              alt={'image'}
              className={[
                'h-[80px] w-[80px] rounded-[80px] overflow-hidden',
                chooseIndex === 0 ? 'border-2 border-cosmebi-pink' : '',
              ].join(' ')}
            />
          </div>
        ) : (
          <label
            htmlFor='choose-my-image'
            className='flex justify-center items-center w-[80px] h-[80px] rounded-[80px] border border-cosmebi-pink
        text-cosmebi-pink font-bold text-xl cursor-pointer'
          >
            画像
            <br/>
            選択
            <input id='choose-my-image' type='file' hidden={true} onChange={readFile}/>
          </label>
        )
      }
      {
        CustomerIcons.map((icon, index) => (
          <div key={index} className='cursor-pointer' onClick={() => setChooseIndex(index+1)}>
            <ImageWithCoverLayout
              src={icon}
              alt='icon'
              className={[
                'h-[80px] w-[80px] rounded-[80px]',
                chooseIndex === index+1 ? 'border-2 border-cosmebi-pink' : ''
              ].join(' ')}
            />
          </div>
        ))
      }
    </div>
  )
}