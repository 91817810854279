import React from "react";
import { Brand } from "../../types/typescript-axios";
import { useManager } from "../../components/contexts/ManagerProvider";
import { useNavigate } from "react-router-dom";
import { authorizedRequest } from "../../utils/api-request";
import { BrandForm } from "../../components/templates/brands/BrandForm";

export type BrandCreateFunction = (data: Omit<Brand, 'id' | 'maker' | 'displayName'> & { makerId: number }) => void
export const NewBrand = () => {
  const manager = useManager()
  const navigate = useNavigate()

  const submit: BrandCreateFunction = async ({
                                               name, subName, akasatana, channel,
                                               searchWord, topPageUrl, purchaseUrl, isPublished, makerId
                                             }) => {
    await authorizedRequest(manager, 'CreateBrand', {
      name, subName, akasatana, channel, searchWord,
      topPageUrl, purchaseUrl, isPublished,
      makerId,
    })
    navigate('/brands/list')
  }

  return <BrandForm create={submit}/>
}
