import React from "react";
import { FetchListView } from "../../components/templates/FetchListView";
import { useManager } from "../../components/contexts/ManagerProvider";
import { ElementInList } from "../../components/parts/ElementInList";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Product, ProductStatus } from "../../types/typescript-axios";
import { ProductModal } from "../../components/templates/products/ProductModal";
import { authorizedRequest } from "../../utils/api-request";
import { toDateTimeString } from "../../utils/toDateTimeString";
import { downloadCsv } from "../../lib/download-csv";
import { customerStatusToString } from "../../utils/customerStatusToString";
import { officialStatusToString } from "../../utils/officialStatusToString";
import { productStatusToString } from "../../utils/productStatusToString";
import { toDateString } from "../../utils/toDateString";

function ListElement({elem}: { elem: Product }) {
  return (
    <ElementInList>
      <span className='inline-block w-[72px]'>
        {elem.id}
      </span>
      {
        elem.productImages &&
        <img src={elem.productImages[0]?.url} className='ml-4 h-full w-[58px]' alt='商品'/>
      }
      <Link to={`/products/${elem.id}`} className='ml-4 w-[320px] list-link'>
        {elem.name}
      </Link>
      <div className='ml-4 items-center text-sm w-[200px]'>
        <div>{elem?.brand?.name}</div>
        <div>{elem?.maker?.name}</div>
      </div>
      <div className='ml-8 w-[80px]'>
        {
          elem?.productStatusId === 1 ? '公開' :
            elem?.productStatusId === 2 ? '非公開' : '廃止'
        }
      </div>
      <div className='ml-8 text-sm w-[160px]'>
        更新：{toDateTimeString(elem.updateDate)}
      </div>
      <div className='ml-8 text-sm w-[160px]'>
        作成：{toDateTimeString(elem.createDate)}
      </div>
    </ElementInList>
  )
}

export const ProductList: React.FunctionComponent = () => {
  const manager = useManager()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  async function fetch({
                         query,
                         page,
                         per
                       }: { query: string | null, page: number | null, per: number | null }): Promise<[Product[], number]> {
    const response = await fetchData({query, page, per})
    if (response.status !== 200) return [[], 0]

    const {data} = response
    return [data.products, data.count]
  }

  async function csvDownload() {
    const query = searchParams.get('query')
    const response = await fetchData(({page: 1, per: 5000, query}))
    if (response.status !== 200) return

    const {products} = response.data
    const headers = [
      'ID', '商品名', 'ステータス', '商品説明',
      '基準価格', '価格・容量', '単価',
      '発売日', '購入URL', 'AmazonURL', 'YahooURL', '楽天URL', 'Qoo10URL',
      '公開日', 'メモ',
    ]

    downloadCsv([
      headers,
      ...products.map(product => [
        product.id, product.name,
        productStatusToString(product.productStatusId), product.descriptionDetail,
        product.price02, product.priceText, product.priceUnit,
        product.releaseDate, product.productUrl,
        product.affiliate1, product.affiliate2, product.affiliate3, product.affiliate4,
        toDateString(product.applyDate), product.note,
      ])
    ], '商品一覧.csv')
  }

  async function fetchData({query, page, per}: { query: string | null, page: number | null, per: number | null }) {
    const channels = searchParams.get('channels')?.split(',')
    const statuses = searchParams.get('statuses')?.split(',')?.map(s => Number(s))
    const categoryId = searchParams.get('category')
    const makerId = searchParams.get('maker')
    const brandId = searchParams.get('brand')
    const releaseDateFrom = searchParams.get('release-from')
    const releaseDateTill = searchParams.get('release-till')
    const createDateFrom = searchParams.get('create-from')
    const createDateTill = searchParams.get('create-till')
    const updateDateFrom = searchParams.get('update-from')
    const updateDateTill = searchParams.get('update-till')

    return await authorizedRequest(manager, 'IndexProducts', {
      ...(query && {query}),
      ...(page && {page}),
      ...(per && {per}),
      ...(channels && {channels}),
      ...(statuses && {statuses: statuses as ProductStatus[]}),
      ...(categoryId && {categoryId: Number(categoryId)}),
      ...(makerId && {makerId: Number(makerId)}),
      ...(brandId && {brandId: Number(brandId)}),
      ...(releaseDateFrom && {releaseDateFrom}),
      ...(releaseDateTill && {releaseDateTill}),
      ...(createDateFrom && {createDateFrom}),
      ...(createDateTill && {createDateTill}),
      ...(updateDateFrom && {updateDateFrom}),
      ...(updateDateTill && {updateDateTill}),
    })
  }

  return (
    <>
      <FetchListView<Product>
        title='商品一覧'
        fetch={fetch}
        create={() => navigate('/products/new')}
        headerElement={<ProductModal csvDownload={csvDownload}/>}
        ListElemComp={ListElement}
      />
    </>
  )
}
