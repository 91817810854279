import { Category, Kodawari } from "../../types/typescript-axios";
import React, { useEffect, useState } from "react";
import { useManager } from "../contexts/ManagerProvider";
import { authorizedRequest } from "../../utils/api-request";

type CategoryKodawarisHash = {
  [categoryId: string]: {
    category: Category,
    kodawaris: Kodawari[]
  }
}

export const ChooseKodawariModal = (
  {initialKodawaris, choosed, cancel}:
    { initialKodawaris: Kodawari[], choosed: (kodawaris: Kodawari[]) => void, cancel: () => void }
) => {
  const manager = useManager()

  const [chosenKodawaris, setChosenKodawaris] = useState<Kodawari[]>(initialKodawaris)
  const [kodawaris, setKodawaris] = useState<CategoryKodawarisHash>({})
  const [searchWord, setSearchWord] = useState<string>('')

  useEffect(() => {
    async function fetchKodawaris() {
      const response = await authorizedRequest(manager, 'IndexKodawaris')
      if (response.status !== 200) return

      const {data: {kodawaris}} = response
      const kodawariObject = kodawaris.reduce((prev: CategoryKodawarisHash, cur: Kodawari) => {
        if(!prev[cur.category.id]) prev[cur.category.id] = { category: cur.category, kodawaris: [] }
        prev[cur.category.id].kodawaris.push(cur)
        return prev
      }, {})

      console.log(kodawariObject)
      setKodawaris(kodawariObject)
    }

    fetchKodawaris()
  }, [])

  function chooseKodawari(kodawari: Kodawari) {
    setChosenKodawaris(kodawaris => {
      if (kodawaris.map(t => t.id)?.includes(kodawari.id)) {
        return kodawaris.filter(t => t.id !== kodawari.id)
      } else {
        return [...kodawaris, kodawari]
      }
    })
  }

  return (
    <div className='fixed h-screen w-screen top-0 left-0 z-50 flex justify-center items-center'
         style={{backgroundColor: 'rgba(0, 0, 0, .5)'}}>
      <div className='w-[80%] h-[80%] bg-white rounded-3xl p-[32px] overflow-scroll'>
        <h2>こだわりの選択</h2>
        <div>
          {
            kodawaris && Object.keys(kodawaris).map((categoryId, index) => (
              <div className='mt-4'>
                <label className='font-bold'>{ kodawaris[categoryId].category.categoryName }</label>
                <div>
                  {
                    kodawaris[categoryId].kodawaris.map((kodawari, index) => ((
                      <>
                        <input
                          id={`kodawari-${kodawari.id}`} className='w-[14px]' type='checkbox'
                          onChange={() => chooseKodawari(kodawari)}
                          checked={chosenKodawaris.map(k => k.id).includes(kodawari.id)}
                        />
                        <label
                          key={index}
                          htmlFor={`kodawari-${kodawari.id}`}
                          className='ml-1 mr-4'
                        >
                          {kodawari.name}
                        </label>
                      </>
                    )))
                  }
                </div>
              </div>
            ))
          }
        </div>
        <div className='flex justify-center mt-[24px]'>
          <button className='gray-btn' onClick={cancel}>キャンセル</button>
          <button className='ml-14' onClick={() => choosed(chosenKodawaris)}>選択</button>
        </div>
      </div>
    </div>
  )
}
