import React, { FormEvent, useEffect, useState } from "react";
import { useManager } from "../../components/contexts/ManagerProvider";
import { apiConfiguration } from "../../config/api-configuration";
import { FetchListView } from "../../components/templates/FetchListView";
import { ElementInList } from "../../components/parts/ElementInList";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ConfirmModal } from "../../components/parts/ConfirmModal";
import { Category } from "../../types/typescript-axios";
import { authorizedRequest } from "../../utils/api-request";
import { downloadCsv } from "../../lib/download-csv";

function ListElement({elem}: { elem: Category }) {
  const manager = useManager()

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [newName, setNewName] = useState<string>(elem.categoryName)
  const [newSortNo, setNewSortNo] = useState<number>(elem.sortNo)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false)

  useEffect(() => {
    setNewName(elem.categoryName)
  }, [elem])

  async function submit() {
    await authorizedRequest(manager, 'UpdateCategory', {
      id: elem.id,
      categoryName: newName,
      sortNo: newSortNo,
    })
    window.location.reload()
  }

  async function destroy() {
    await authorizedRequest(manager, 'DestroyCategory', {
      id: elem.id,
    })
    window.location.reload()
  }

  return (
    <ElementInList>
      <div className='flex flex-1 items-center justify-between'>
        {
          isEdit ? (
            <span className='flex items-center'>
              <input value={newName} onChange={(e) => setNewName(e.target.value)}/>
              <input
                type='number' value={newSortNo}
                className='ml-8 w-[100px]'
                onChange={(e) => setNewSortNo(Number(e.target.value))}
              />
              <button className='small-btn ml-2' onClick={submit}>決定</button>
              <button className='gray-btn small-btn ml-4' onClick={() => setIsEdit(false)}>キャンセル</button>
            </span>
          ) : (
            <div className='flex gap-x-16'>
              <Link className='list-link w-[280px]' to={`/categories/list/${elem.id}`}>
                {elem.categoryName}
              </Link>
              <span>順番：{elem.sortNo}</span>
            </div>
          )
        }
        <div>
          <button className='gray-btn h-[42px] w-[68px]' onClick={() => setIsEdit(true)}>編集</button>
          <button className='small-btn red-btn ml-4' onClick={() => setShowConfirmDeleteModal(true)}>削除</button>
        </div>
      </div>
      {
        showConfirmDeleteModal && (
          <ConfirmModal
            title='カテゴリーの削除'
            content='削除したら戻すことはできません。本当に削除しますか？'
            okLogic={destroy}
            cancelLogic={() => setShowConfirmDeleteModal(false)}
          />
        )
      }
    </ElementInList>
  )
}

function Breadcrumbs({categories, csvDownload}: { categories: Category[], csvDownload: () => void }) {
  return (
    <div className='flex items-center gap-1'>
      <button
        className='blue-btn w-[150px] h-[45px] mr-8'
        onClick={csvDownload}
      >
        CSVダウンロード
      </button>
      <Link className='text-sm text-blue-500' to='/categories/list'>すべてのカテゴリ</Link>
      {
        categories.map(c => (
          <span className='flex items-center'>
            {'>'} <Link className='ml-1 text-sm text-blue-500' to={`/categories/list/${c.id}`}>{c.categoryName}</Link>
          </span>
        ))
      }
    </div>
  )
}

export const CategoryList: React.FunctionComponent = () => {
  const {parentCategoryId} = useParams()
  const manager = useManager()
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [newCategoryName, setNewCategoryName] = useState<string>()
  const [newCategorySortNo, setNewCategorySortNo] = useState<number>()
  const [breadscrumbs, setBreadscrubs] = useState<Category[]>([])
  const [searchParams] = useSearchParams()

  async function fetch({
                         query,
                         page,
                         per
                       }: { query: string | null, page: number | null, per: number | null }): Promise<[Category[], number]> {
    const response = await authorizedRequest(manager, 'IndexCategories', {
      ...(parentCategoryId && {parentCategoryId: Number(parentCategoryId)}),
      ...(query && {query}),
      ...(page && {page}),
      ...(per && {per}),
      all: false,
    })
    if (response.status !== 200) return [[], 0]

    const { data } = response
    setBreadscrubs(data.breadcrumbs)
    return [data.categories, data.count]
  }

  async function submitCreate(e: FormEvent) {
    e.preventDefault()
    if (!newCategoryName) return

    await authorizedRequest(manager, 'CreateCategory', {
      categoryName: newCategoryName,
      sortNo: newCategorySortNo,
      ...(parentCategoryId && {parentCategoryId: Number(parentCategoryId)}),
    })
    window.location.reload()
  }

  async function csvDownload() {
    const query = searchParams.get('query')
    const response = await fetchData(({page: 1, per: 5000, query, all: true}))
    if (response.status !== 200) return

    const {categories} = response.data
    const headers = [
      'ID', '名前', '順番',
      '親カテゴリーID',
    ]

    downloadCsv([
      headers,
      ...categories.map(category => [
        category.id, category.categoryName, category.sortNo,
        category.parentCategoryId,
      ])
    ], 'カテゴリ一覧.csv')
  }

  async function fetchData({query, page, per, all}: { query: string | null, page: number | null, per: number | null, all: boolean }) {
    return await authorizedRequest(manager, 'IndexCategories', {
      ...(parentCategoryId && {parentCategoryId: Number(parentCategoryId)}),
      ...(query && {query}),
      ...(page && {page}),
      ...(per && {per}),
      all,
    })
  }

  return (
    <>
      <FetchListView<Category>
        title='カテゴリ管理'
        fetch={fetch}
        ListElemComp={ListElement}
        create={() => setShowCreateModal(true)}
        headerElement={<Breadcrumbs categories={breadscrumbs} csvDownload={csvDownload} />}
      />
      {
        showCreateModal && (
          <div
            className='absolute top-0 left-0 flex items-center justify-center w-screen h-screen'
            style={{backgroundColor: 'rgba(0, 0, 0, .5)'}}
            onClick={() => setShowCreateModal(false)}
          >
            <div className='flex flex-col bg-white p-10 rounded-xl' onClick={(e) => {
              e.stopPropagation()
            }}>
              <h2>カテゴリの新規作成</h2>
              <form onSubmit={submitCreate} className='text-center'>
                <input
                  placeholder='名前' value={newCategoryName}
                  onChange={(e) => setNewCategoryName(e.target.value)}
                  required
                />
                <br />
                <input
                  className='mt-8'
                  type='number'
                  placeholder='順番' value={newCategorySortNo}
                  onChange={(e) => setNewCategorySortNo(Number(e.target.value))}
                  required
                />
                <br />
                <button className='mt-16 ml-4 green-btn'>作成</button>
              </form>
            </div>
          </div>
        )
      }
    </>
  )
}
