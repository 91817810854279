import React, { FormEvent, useEffect, useState } from 'react';
import { UserContentStatus } from '../../types/typescript-axios';
import { useNavigate, useSearchParams } from "react-router-dom";

type Props = {
  csvDownload: () => void
}
export function ReportModal({ csvDownload }: Props): JSX.Element {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchStatusIds, setSearchStatusIds] = useState<UserContentStatus[]>([1,2])
  const [searchCreateDateFrom, setSearchCreateDateFrom] = useState<string>()
  const [searchCreateDateTill, setSearchCreateDateTill] = useState<string>()

  useEffect(() => {
    const statusIds = searchParams.get('status')?.split(',').map(id => Number(id))
    const from = searchParams.get('from')
    const till = searchParams.get('till')

    if (statusIds) setSearchStatusIds(statusIds as UserContentStatus[])
    if (from) setSearchCreateDateFrom(from)
    if (till) setSearchCreateDateTill(till)
  }, [searchParams])

  function toggleStatusId(status: UserContentStatus) {
    if (searchStatusIds.includes(status)) setSearchStatusIds(searchStatusIds.filter(id => id !== status))
    else setSearchStatusIds([...searchStatusIds, status])
  }

  function search(e: FormEvent) {
    e.preventDefault()

    const param = new URLSearchParams()
    param.set('status', searchStatusIds.join(','))
    if (searchCreateDateFrom) param.set('from', searchCreateDateFrom)
    if (searchCreateDateTill) param.set('till', searchCreateDateTill)
    navigate('/reports/list?' + param.toString())
    setShowModal(false)
  }

  return (
    <>
      <button
        className='blue-btn w-[150px] h-[45px] mr-8'
        onClick={csvDownload}
      >
        CSVダウンロード
      </button>
      <button onClick={() => setShowModal(true)} className='w-[100px] h-[45px] bg-blue-400 duration-200'>
        詳細検索
      </button>
      {
        showModal && (
          <form
            className='fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black-transparent z-50'
            onSubmit={search}
          >
            <div className='flex flex-col items-center w-[800px] max-h-[80vh] bg-white py-40'>
              <div className='w-[680px]'>
                <div className="mt-3 text-center">
                  <h1
                    className=" font-medium text-blue-400"
                    id="modal-title">
                    みんなの美容レポ管理詳細検索
                  </h1>
                </div>
                <div className='mb-[25px] mx-[20px] mt-[25px]'>
                  <label className='text-[15px] mb-[5px] mr-[125px]'>投稿日</label>
                  <input
                    type="date"
                    className='w-[180px] mr-[10px] h-[35px] shadow-sm'
                    value={searchCreateDateFrom}
                    onChange={(e) => setSearchCreateDateFrom(e.target.value)}
                  />
                  <span className=''>〜</span>
                  <input
                    type="date"
                    className='w-[180px] ml-[10px] h-[35px] shadow-sm'
                    value={searchCreateDateTill}
                    onChange={(e) => setSearchCreateDateTill(e.target.value)}
                  />
                </div>
                <div className='mb-[15px] mx-[20px] flex'>
                  <p className='mb-[10px] text-[15px] mr-[65px]'>公開ステータス</p>
                  <div className='flex items-center mb-[25px]'>
                    <div className='flex items-center mr-[25px]'>
                      <input
                        id='0'
                        type="checkbox"
                        className='w-[15px]'
                        checked={searchStatusIds.includes(1)}
                        onChange={() => toggleStatusId(1)}
                      />
                      <label htmlFor='0' className='text-[15px] ml-[8px] w-[60px]'>公開</label>
                    </div>
                    <div className='flex items-center mr-[25px]'>
                      <input
                        id='1'
                        type="checkbox"
                        className='w-[15px]'
                        checked={searchStatusIds.includes(2)}
                        onChange={() => toggleStatusId(2)}
                      />
                      <label htmlFor='1' className='text-[15px] ml-[8px] w-[60px]'>非公開</label>
                    </div>
                    <div className='flex items-center mr-[25px]'>
                      <input
                        id='2'
                        type="checkbox"
                        className='w-[15px]'
                        checked={searchStatusIds.includes(8)}
                        onChange={() => toggleStatusId(8)}
                      />
                      <label htmlFor='2' className='text-[15px] ml-[8px] w-[60px]'>廃止</label>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-1 flex justify-center">
                  <button
                    className="mt-3 w-[200px] h-[45px] inline-flex justify-center rounded-[30px] bg-blue-400 shadow-sm px-4 py-2 text-white hover:bg-blue-500 font-bold duration-300 mr-[20px]">
                    この条件で検索する
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-[200px] h-[45px] inline-flex justify-center rounded-[30px] border border-blue-400 shadow-sm px-4 py-2 text-blue-400 hover:bg-blue-400 hover:text-white font-bold duration-300"
                    onClick={() => setShowModal(false)}>
                    戻る
                  </button>
                </div>
              </div>
            </div>
          </form>
        )
      }
    </>
  );
}
