import { ReactNode } from "react";

export const ElementInList = ({ children, onClick }: { children: ReactNode, onClick?: () => void }) => {
  return (
    <div
      className='flex items-center px-6 border rounded-xl border-gray-300 h-[60px]'
      onClick={onClick}
    >
      { children }
    </div>
  )
}