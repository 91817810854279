import React from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ManagerProvider, useManager } from "./components/contexts/ManagerProvider";
import { Login } from "./pages/Login";
import { NewPasswordRequired } from "./pages/auth/NewPasswordRequired";
import { MakerList } from "./pages/makers/MakerList";
import { CategoryList } from "./pages/categories/CategoryList";
import { ProductList } from "./pages/products/ProductList";
import { ManagerList } from "./pages/managers/ManagerList";
import { CustomerList } from "./pages/customers/CustomerList";
import { ProductReviewList } from "./pages/product-reviews/ProductReviewList";
import { RankingAnalysis } from "./pages/RankingAnalysis";
import { QandAList } from "./pages/q-and-a/QandAList";
import { QaAnswerList } from "./pages/qa-answers/QaAnswerList";
import { BrandList } from "./pages/brands/BrandList";
import { NewBrand } from "./pages/brands/NewBrand";
import { CustomerDetail } from "./pages/customers/CustomerDetail";
import { NewManager } from "./pages/managers/NewManager";
import { ProductReviewDetail } from "./pages/product-reviews/ProductReviewDetail";
import { QandADetail } from "./pages/q-and-a/QandADetail";
import { QaAnswerDetail } from "./pages/qa-answers/QaAnswerDetail";
import { ProductDetail } from './pages/products/ProductDetail';
import { AdList } from "./pages/ads/AdList";
import { NewAd } from "./pages/ads/NewAd";
import { AdDetail } from "./pages/ads/AdDetail";
import { NewProduct } from "./pages/products/NewProduct";
import { ReportList } from "./pages/reports/ReportList";
import { ReportDetail } from "./pages/reports/ReportDetail";
import { RecommendList } from "./pages/products/RecommendList";
import { NewRecommend } from "./pages/products/NewRecommend";
import { TagList } from "./pages/tags/TagList";
import { NewCustomer } from "./pages/customers/NewCustomer";
import { CategoryProvider } from "./components/contexts/category-provider";
import { BrandDetail } from "./pages/brands/BrandDetail";
import { EditManager } from "./pages/managers/EditManager";
import { ManagerAuthority } from "./types/typescript-axios";
import { PresentList } from "./pages/presents/PresentList";
import { GlobalNotificationList } from "./pages/global-notifications/GlobalNotificationList";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

function App() {
  return (
    <ManagerProvider>
      <CategoryProvider>
        <DndProvider backend={HTML5Backend}>
          <AppRoutes />
        </DndProvider>
      </CategoryProvider>
    </ManagerProvider>
  );
}

function AppRoutes() {
  const manager = useManager()

  return (
    <>
      {
        manager ? (
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<ProductList />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/auth/new-password-required" element={<NewPasswordRequired />}></Route>
              {
                manager.authority === ManagerAuthority.Staff && (
                  <>
                    <Route path="/makers/list" element={<MakerList />}></Route>
                    <Route path="/categories/list" element={<CategoryList />} />
                    <Route path="/categories/list/:parentCategoryId" element={<CategoryList />} />
                    <Route path="/products/list" element={<ProductList />} />
                    <Route path="/products/new" element={<NewProduct />} />
                    <Route path="/products/recommends" element={<RecommendList />} />
                    <Route path="/products/recommends/new" element={<NewRecommend />} />
                    <Route path="/products/:id" element={<ProductDetail />} />
                    <Route path="/customers/list" element={<CustomerList />} />
                    <Route path="/customers/new" element={<NewCustomer />} />
                    <Route path="/customers/:id" element={<CustomerDetail />} />
                    <Route path="/product-reviews/list" element={<ProductReviewList />} />
                    <Route path="/product-reviews/:id" element={<ProductReviewDetail />} />
                    <Route path='/ranking-analysis' element={<RankingAnalysis />} />
                    <Route path='/reports/list' element={<ReportList />} />
                    <Route path='/reports/:id' element={<ReportDetail />} />
                    <Route path='/q-and-a' element={<QandAList />} />
                    <Route path='/q-and-a/:id' element={<QandADetail />} />
                    <Route path='/qa-answers' element={<QaAnswerList />} />
                    <Route path='/qa-answers/:id' element={<QaAnswerDetail />} />
                    <Route path='/brands/list' element={<BrandList />} />
                    <Route path='/brands/new' element={<NewBrand />} />
                    <Route path='/brands/:id' element={<BrandDetail />} />
                    <Route path='/ads' element={<AdList />} />
                    <Route path='/ads/new' element={<NewAd />} />
                    <Route path='/ads/:id' element={<AdDetail />} />
                    <Route path='/tags' element={<TagList />} />
                    <Route path='/presents' element={<PresentList />} />
                    <Route path='/global-notifications' element={<GlobalNotificationList />} />
                  </>
                )
              }
              {
                manager.authority === ManagerAuthority.Admin && (
                  <>
                    <Route path="/makers/list" element={<MakerList />}></Route>
                    <Route path="/categories/list" element={<CategoryList />} />
                    <Route path="/categories/list/:parentCategoryId" element={<CategoryList />} />
                    <Route path="/products/list" element={<ProductList />} />
                    <Route path="/products/new" element={<NewProduct />} />
                    <Route path="/products/recommends" element={<RecommendList />} />
                    <Route path="/products/recommends/new" element={<NewRecommend />} />
                    <Route path="/products/:id" element={<ProductDetail />} />
                    <Route path="/customers/list" element={<CustomerList />} />
                    <Route path="/customers/new" element={<NewCustomer />} />
                    <Route path="/customers/:id" element={<CustomerDetail />} />
                    <Route path="/product-reviews/list" element={<ProductReviewList />} />
                    <Route path="/product-reviews/:id" element={<ProductReviewDetail />} />
                    <Route path='/ranking-analysis' element={<RankingAnalysis />} />
                    <Route path='/reports/list' element={<ReportList />} />
                    <Route path='/reports/:id' element={<ReportDetail />} />
                    <Route path='/q-and-a' element={<QandAList />} />
                    <Route path='/q-and-a/:id' element={<QandADetail />} />
                    <Route path='/qa-answers' element={<QaAnswerList />} />
                    <Route path='/qa-answers/:id' element={<QaAnswerDetail />} />
                    <Route path='/brands/list' element={<BrandList />} />
                    <Route path='/brands/new' element={<NewBrand />} />
                    <Route path='/brands/:id' element={<BrandDetail />} />
                    <Route path='/ads' element={<AdList />} />
                    <Route path='/ads/new' element={<NewAd />} />
                    <Route path='/ads/:id' element={<AdDetail />} />
                    <Route path='/tags' element={<TagList />} />
                    <Route path='/presents' element={<PresentList />} />
                    <Route path='/global-notifications' element={<GlobalNotificationList />} />
                    <Route path="/managers/list" element={<ManagerList />} />
                    <Route path="/managers/new" element={<NewManager />} />
                    <Route path="/managers/:id" element={<EditManager />} />
                  </>
                )
              }
              {
                manager.authority === ManagerAuthority.Guest && (
                  <>
                    <Route path="/products/list" element={<ProductList />} />
                    <Route path="/products/new" element={<NewProduct />} />
                    <Route path="/products/:id" element={<ProductDetail />} />
                  </>
                )
              }
            </Routes>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />}></Route>
              <Route path="*" element={<Navigate to='/login' replace={true}/>}/>
            </Routes>
          </BrowserRouter>
        )
      }
    </>
  )
}
export default App;
