import React from "react";
import { FetchListView } from "../../components/templates/FetchListView";
import { useManager } from "../../components/contexts/ManagerProvider";
import { ElementInList } from "../../components/parts/ElementInList";
import { Link, useSearchParams } from "react-router-dom";
import { TranslateProductReviewRecommendRevel } from "../../translations/translate-product-review-recommend-revel";
import { translateProductReviewStatus } from "../../translations/translate-product-review-status";
import { ProductReview, RecommendLevel, UserContentStatus } from "../../types/typescript-axios";
import { ProductReviewModal } from "./ProductReviewModal";
import { authorizedRequest } from "../../utils/api-request";
import { downloadCsv } from "../../lib/download-csv";
import { userContentStatusToString } from "../../utils/userContentStatusToString";
import { toDateTimeString } from "../../utils/toDateTimeString";
import { officialStatusToString } from "../../utils/officialStatusToString";


function ListElement({elem}: { elem: ProductReview }) {
  const manager = useManager()

  async function destroy() {
    if (window.confirm('本当に削除しますか？')) {
      const response = await authorizedRequest(manager, 'DestroyProductReview', { id: elem.id })
      if (response.status === 200) {
        window.location.reload()
      }
    }
  }

  async function toggleStatus() {
    if (window.confirm(`本当に${elem.statusId === 1 ? '非公開' : '公開'}にしますか？`)) {
      const response = await authorizedRequest(manager, 'UpdateProductReview', { id: elem.id, statusId: elem.statusId === 1 ? 2 : 1 })
      if (response.status === 200) {
        window.location.reload()
      }
    }   
  }

  return (
    <ElementInList>
      <div className='flex items-center text-sm'>
        <span className='inline-block w-[62px]'>{elem.id}</span>
        <Link to={`/customers/${elem.customer.id}`} className='list-link w-[140px]'>{elem.customer.nicname}</Link>
        <Link to={`/products/${elem.product.id}`} className='list-link w-[260px]'>{elem.product.name}</Link>
        <Link to={`/product-reviews/${elem.id}`} className='list-link w-[260px]'>{elem.title}</Link>
        <span className='w-[100px]'>
          {elem.recommendLevel && <TranslateProductReviewRecommendRevel level={elem.recommendLevel}/>}
        </span>
        <span className='w-[100px] text-center'>{elem.statusId && translateProductReviewStatus(elem.statusId)}</span>
        <span className='flex-1' />
        <button className='red-btn h-[42px] px-[18px] w-[75px]' onClick={destroy}>削除</button>
        {elem.statusId !== 8 && (
          <button className='white-btn h-[42px] px-[18px] w-[75px] ml-4' onClick={toggleStatus}>{ elem.statusId===1 ? '非公開' : '公開'}</button>
        )}
        
      </div>
    </ElementInList>
  )
}

export const ProductReviewList: React.FunctionComponent = () => {
  const manager = useManager()
  const [searchParams] = useSearchParams()

  async function fetch({
                         query,
                         page,
                         per
                       }: { query: string | null, page: number | null, per: number | null }): Promise<[ProductReview[], number]> {
    const response = await fetchData({ query, page, per })
    if (response.status !== 200) return [[], 0]

    const { data } = response
    return [data.productReviews, data.count]
  }

  async function csvDownload() {
    const query = searchParams.get('query')
    const response = await fetchData(({page: 1, per: 5000, query}))
    if (response.status !== 200) return

    const {productReviews} = response.data
    const headers = [
      'ID', '投稿日時', '商品ID', '商品名',
      '投稿者ID', '投稿者ニックネーム',
      'オフィシャルメンバーステータス', 
      'おすすめ評価', 'タイトル', 'コメント'
    ]

    downloadCsv([
      headers,
      ...productReviews.map(review => [
        review.id, toDateTimeString(review.updateDate),
        review.product.id, review.product.name,
        review.customer.id, review.customer.nicname,
        officialStatusToString((review.customer.officialStatusId)),
        review.recommendLevel, review.title, review.comment,
        userContentStatusToString(review.statusId),
      ])
    ], 'クチコミ一覧.csv')
  }

  async function fetchData({query, page, per}: { query: string | null, page: number | null, per: number | null }) {
    const statusIds = searchParams.get('status')?.split(',')?.map(id => Number(id))
    const recommendLevel = searchParams.get('recommend')
    const productName = searchParams.get('product-name')
    const from = searchParams.get('from')
    const till = searchParams.get('till')

    return await authorizedRequest(manager, 'IndexProductReviews', {
      ...(statusIds && { statuses: statusIds as UserContentStatus[] }),
      ...(productName && { productName }),
      ...(recommendLevel && { recommendLevel: Number(recommendLevel) as RecommendLevel }),
      ...(from && { applyDateFrom: from }),
      ...(till && { applyDateTill: till }),
      ...(query && {query}),
      ...(page && {page}),
      ...(per && {per}),
    })
  }


  return (
    <>
    <FetchListView
      title='クチコミ一覧'
      fetch={fetch}
      ListElemComp={ListElement}
      headerElement={<ProductReviewModal csvDownload={csvDownload} />}
    />
    </>
  )
}
