import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { useEffect, useState } from "react";
import { Ad, UpdateAdRequestData } from "../../types/typescript-axios";
import { useManager } from "../../components/contexts/ManagerProvider";
import { useNavigate, useParams } from "react-router-dom";
import { AdForm } from "./common/AdForm";
import { authorizedRequest } from "../../utils/api-request";

export const AdDetail = () => {
  const manager = useManager()
  const navigate = useNavigate()
  const { id } = useParams()
  const [ad, setAd] = useState<Ad>()

  useEffect(() => {
    async function fetchData() {
      if (!id) return
      const response = await authorizedRequest(manager, 'ShowAd', { id: +id })
      if (response.status !== 200) return

      const { data: { ad }} = response
      setAd(ad)
    }
    fetchData()
  }, [])

  async function submit(params: UpdateAdRequestData) {
    await authorizedRequest(manager, 'UpdateAd', params)
    navigate('/ads')
  }

  if (!ad) return <SidebarLayout />

  return (
    <SidebarLayout>
      <h1>広告詳細</h1>
      <AdForm ad={ad} submit={submit} />
    </SidebarLayout>
  )
}
