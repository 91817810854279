import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Category } from "../../types/typescript-axios";
import { ManagerContext, useManager } from "./ManagerProvider";
import { authorizedRequest } from "../../utils/api-request";

const CategoryContext =
  React.createContext<[Category[], Dispatch<SetStateAction<Category[]>>, (fetch: boolean) => void]>([[], () => {}, () => {}])

export const CategoryProvider: React.FunctionComponent = ({ children }) => {
  const manager = useManager()
  const [categories, setCategories] = useState<Category[]>([])
  const [fetched, setFetched] = useState<boolean>(false)

  useEffect(() => {
    if (!manager || !fetched) return

    async function fetchCategories() {
      const response = await authorizedRequest(manager, 'IndexCategories', { all: true })
      if (response.status === 200)  setCategories(response.data.categories)
    }
    fetchCategories()
  }, [manager, fetched])

  return (
    <CategoryContext.Provider value={[categories, setCategories, setFetched]}>
      { children }
    </CategoryContext.Provider>
  )
}

export const useCategories = () => {
  const [categories, _, setFetched] = useContext(CategoryContext)
  setFetched(true)
  return categories
}
