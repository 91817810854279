import { useManager } from "../../components/contexts/ManagerProvider";
import React, { useState } from "react";
import { Ad, IndexAdsRequestDataAdTypeEnum } from "../../types/typescript-axios";
import { FetchListView } from "../../components/templates/FetchListView";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ElementInList } from "../../components/parts/ElementInList";
import { SelectWithType } from "../../components/molecules/SelectWithType";
import { authorizedRequest } from "../../utils/api-request";
import { toDateString } from "../../utils/toDateString";
import { AdTypeName } from "../../constants/ad-type-name";
import { objectKeys, objectMap } from "../../lib/object-keys";

function ListElement({elem}: { elem: Ad }) {
  return (
    <ElementInList>
      <span className='w-[60px]'>{elem.id}</span>
      <Link to={`/ads/${elem.id}`} className='w-[240px] list-link'>{elem.name}</Link>
      <span className='w-[180px]'>{elem.enabled ? '有効' : '無効'}</span>
      <span className='w-[180px]'>{AdTypeName[elem.adType]}</span>
      {
        (elem.publishFrom || elem.publishTill) && (
          <span className='w-[240px]'>
            {toDateString(elem.publishFrom)}
            <span className='px-8'>〜</span>
            {toDateString(elem.publishTill)}
          </span>
        )
      }
    </ElementInList>
  )
}

function HeaderElement({
                         onEnabledChange,
                         onAdTypeChange,
                         onDateChange,
                       }: { onEnabledChange: (str: boolean | undefined) => void, onAdTypeChange: (str: string | undefined) => void, onDateChange: (str: string | undefined) => void }) {
  const [enabled, setEnabled] = useState<boolean>()
  const [adType, setAdType] = useState<string>()
  const [date, setDate] = useState<string>('')

  return (
    <div className='flex justify-end px-[24px] gap-8'>
      <div className='flex flex-col items-center'>
        <SelectWithType<boolean | undefined>
          currentValue={enabled}
          list={[['すべて', undefined], ['有効', true], ['無効', false]]}
          onSelect={(value) => {
            setEnabled(value);
            onEnabledChange(value)
          }}
        />
        <div className='mt-1 text-xs font-semibold'>有効/無効</div>
      </div>
      <div className='flex flex-col items-center ml-4'>
        <SelectWithType<string | undefined>
          currentValue={adType}
          list={[['すべて', undefined], ...objectMap(AdTypeName)]}
          onSelect={(value) => {
            setAdType(value);
            onAdTypeChange(value)
          }}
        />
        <div className='mt-1 text-xs font-semibold'>広告種別</div>
      </div>
      <div className='flex flex-col items-center'>
        <input type='date'
               value={date}
               onChange={(e) => {
                 setDate(e.target.value)
                 onDateChange(e.target.value)
               }}/>
        <div className='mt-1 text-xs font-semibold'>表示日</div>
      </div>
    </div>
  )
}

export const AdList = () => {
  const manager = useManager()
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  async function fetch({
                         query,
                         page,
                         per
                       }: { query: string | null, page: number | null, per: number | null }): Promise<[Ad[], number]> {
    let enabled: boolean | undefined
    if (searchParams.get('enabled')) enabled = searchParams.get('enabled') === 'true'
    let adType: IndexAdsRequestDataAdTypeEnum | undefined
    if (searchParams.get('adType')) adType = searchParams.get('adType') as IndexAdsRequestDataAdTypeEnum
    const date = searchParams.get('date')

    const response = await authorizedRequest(manager, 'IndexAds', {
      ...(query && {query}),
      ...(page && {page}),
      ...(per && {per}),
      ...(enabled !== undefined && {enabled}),
      ...(adType && {adType}),
      ...(date && { date }),
    })
    if (response.status !== 200) return [[], 0]

    return [response.data.ads, response.data.count]
  }

  function onEnabledChange(enabled: boolean | undefined) {
    searchParams.delete('page')
    if (enabled === undefined) searchParams.delete('enabled')
    else searchParams.set('enabled', enabled ? 'true' : 'false')
    navigate({
      search: `?${searchParams}`
    })
  }

  function onAdTypeChange(adType: string | undefined) {
    searchParams.delete('page')
    if (adType === undefined) searchParams.delete('adType')
    else searchParams.set('adType', adType)
    navigate({
      search: `?${searchParams}`
    })
  }

  function onDateChange(date: string | undefined) {
    searchParams.delete('page')
    if (date === undefined) searchParams.delete('date')
    else searchParams.set('date', date)
    navigate({
      search: `?${searchParams}`
    })
  }

  function create() {
    navigate('/ads/new')
  }

  return (
    <FetchListView<Ad>
      title='広告管理'
      fetch={fetch}
      headerElement={<HeaderElement onEnabledChange={onEnabledChange} onAdTypeChange={onAdTypeChange} onDateChange={onDateChange}/>}
      create={create}
      ListElemComp={ListElement}
    />
  )
}
