import React, { FormEvent, useState } from 'react';
import { UserContentStatus } from '../../../types/typescript-axios';
import { useCategories } from "../../contexts/category-provider";
import { Channel } from "../../../utils/channel";
import { useNavigate } from "react-router-dom";

export function OpenRankingAnalysisModal(): JSX.Element {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState<boolean>(false);
  const categories = useCategories()

  const [searchChannels, setSearchChannels] = useState<Channel[]>([])
  const [searchReviewStatusIds, setSearchReviewStatusIds] = useState<UserContentStatus[]>([])
  const [searchCategoryId, setSearchCategoryId] = useState('')
  const [searchProductName, setSearchProductName] = useState<string>('')
  const [searchProductId, setSearchProductId] = useState('')
  const [searchReleaseDateFrom, setSearchReleaseDateFrom] = useState<string>('')
  const [searchReleaseDateTill, setSearchReleaseDateTill] = useState<string>('')
  const [searchReviewUpdatedOrBookmarkedFrom, setSearchReviewUpdatedOrBookmarkedFrom] = useState<string>('')
  const [searchReviewUpdatedOrBookmarkedTill, setSearchReviewUpdatedOrBookmarkedTill] = useState<string>('')

  // モーダルで戻るボタン押下時の処理
  const handleCloseModal = () => {
    setShowModal(false);
  };

  function toggleChannel(name: string) {
    if (searchChannels.includes(name)) setSearchChannels(searchChannels.filter(s => s !== name))
    else setSearchChannels([...searchChannels, name])
  }

  function toggleStatus(status: UserContentStatus) {
    if (searchReviewStatusIds.includes(status)) setSearchReviewStatusIds(searchReviewStatusIds.filter(s => s !== status))
    else setSearchReviewStatusIds([...searchReviewStatusIds, status])
  }

  function search(e: FormEvent) {
    e.preventDefault()

    const param = new URLSearchParams()
    if (searchChannels.length > 0) param.set('channel', searchChannels.join(','))
    if (searchReviewStatusIds.length > 0) param.set('status', searchReviewStatusIds.join(','))
    if (searchProductName.length > 0) param.set('product-name', searchProductName)
    if (searchProductId.length > 0) param.set('product-id', searchProductId)
    if (searchCategoryId) param.set('category-id', searchCategoryId)
    if (searchReleaseDateFrom?.length > 0) param.set('release-from', searchReleaseDateFrom)
    if (searchReleaseDateTill?.length > 0) param.set('release-till', searchReleaseDateTill)
    if (searchReviewUpdatedOrBookmarkedFrom?.length > 0) param.set('post-from', searchReviewUpdatedOrBookmarkedFrom)
    if (searchReviewUpdatedOrBookmarkedTill?.length > 0) param.set('post-till', searchReviewUpdatedOrBookmarkedTill)
    navigate('/ranking-analysis?' + param.toString())
    setShowModal(false)
  }

  return (
    <>
      <button onClick={() => setShowModal(true)} className='w-[100px] h-[45px] bg-blue-400 duration-200'>
        詳細検索
      </button>
      {
        showModal && (
          <form
            onSubmit={search}
            className='fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black-transparent z-50'>
            <div className='flex flex-col items-center w-[800px] max-h-[80vh] bg-white py-40'>
              <div className="mt-[8px] text-center">
                <h1
                  className=" font-medium text-blue-400"
                  id="modal-title">
                  ランキング解析詳細検索
                </h1>
              </div>
              <div className='mb-[15px] mx-[20px] flex'>
                <p className='text-[15px] w-[170px]'>チャネル</p>
                <div className='flex items-center mb-[10px]'>
                  <div className='flex items-center mr-[25px]'>
                    <input
                      id='channel-0'
                      type="checkbox"
                      className='w-[15px]'
                      checked={searchChannels.includes('デパコス')}
                      onChange={() => toggleChannel('デパコス')}
                    />
                    <label htmlFor='channel-0' className='text-[15px] ml-[8px] w-[60px]'>デパコス</label>
                  </div>
                  <div className='flex items-center mr-[25px]'>
                    <input
                      id='channel-1'
                      type="checkbox"
                      className='w-[15px]'
                      checked={searchChannels.includes('セミコス')}
                      onChange={() => toggleChannel('セミコス')}
                    />
                    <label htmlFor='channel-1' className='text-[15px] ml-[8px] w-[60px]'>セミコス</label>
                  </div>
                  <div className='flex items-center mr-[25px]'>
                    <input
                      id='channel-2'
                      type="checkbox"
                      className='w-[15px]'
                      checked={searchChannels.includes('プチプラ')}
                      onChange={() => toggleChannel('プチプラ')}
                    />
                    <label htmlFor='channel-2' className='text-[15px] ml-[8px] w-[60px]'>プチプラ</label>
                  </div>
                  <div className='flex items-center mr-[25px]'>
                    <input
                      id='channel-3'
                      type="checkbox"
                      className='w-[15px]'
                      checked={searchChannels.includes('通販コスメ')}
                      onChange={() => toggleChannel('通販コスメ')}
                    />
                    <label htmlFor='channel-3' className='text-[15px] ml-[8px] w-[90px]'>通販コスメ</label>
                  </div>
                </div>
              </div>
              <div className='mb-[15px] mx-[20px] flex'>
                <p className='mb-[10px] text-[15px] w-[170px]'>クチコミステータス</p>
                <div className='flex items-center mb-[25px]'>
                  <div className='flex items-center mr-[25px]'>
                    <input
                      id='status-0'
                      type="checkbox"
                      className='w-[15px]'
                      checked={searchReviewStatusIds.includes(1)}
                      onChange={(e) => toggleStatus(1)}
                    />
                    <label htmlFor='status-0' className='text-[15px] ml-[8px] w-[60px]'>公開</label>
                  </div>
                  <div className='flex items-center mr-[25px]'>
                    <input
                      id='status-1'
                      type="checkbox"
                      className='w-[15px]'
                      checked={searchReviewStatusIds.includes(2)}
                      onChange={(e) => toggleStatus(2)}
                    />
                    <label htmlFor='status-1' className='text-[15px] ml-[8px] w-[60px]'>非公開</label>
                  </div>
                  <div className='flex items-center mr-[25px]'>
                    <input
                      id='status-2'
                      type="checkbox"
                      className='w-[15px]'
                      checked={searchReviewStatusIds.includes(8)}
                      onChange={(e) => toggleStatus(8)}
                    />
                    <label htmlFor='status-2' className='text-[15px] ml-[8px] w-[60px]'>下書き</label>
                  </div>
                </div>
              </div>
              <div className='flex items-center mb-[15px] mx-[20px]'>
                <label className='text-[15px] mb-[15px] w-[170px]'>カテゴリ</label>
                <select
                  className='w-[370px] h-[35px] shadow-sm mb-[15px]'
                  value={searchCategoryId}
                  onChange={(e) => setSearchCategoryId(e.target.value)}
                >
                  <option value=''>全ての商品</option>
                  {
                    categories.map(category => (
                      <option value={category.id}>{ category.categoryName }</option>
                    ))
                  }
                </select>
              </div>
              <div className='mb-[5px] mx-[20px] flex'>
                <p className='text-[15px] w-[170px]'>商品名</p>
                <div className='flex items-center mb-[25px]'>
                  <input
                    className='h-[35px] w-[370px] shadow-sm'
                    value={searchProductName}
                    onChange={(e) => setSearchProductName(e.target.value)}
                  />
                </div>
              </div>
              <div className='mb-[5px] mx-[20px] flex'>
                <p className='text-[15px] w-[170px]'>商品ID</p>
                <div className='flex items-center mb-[25px]'>
                  <input
                    type='number'
                    className='h-[35px] w-[370px] shadow-sm'
                    value={searchProductId}
                    onChange={(e) => setSearchProductId(e.target.value)}
                  />
                </div>
              </div>
              <div className='mb-[25px] mx-[20px] flex items-center'>
                <label className='text-[15px] mb-[5px] w-[170px]'>発売日</label>
                <input
                  type="date"
                  className='w-[180px] mr-[10px] h-[35px] shadow-sm'
                  value={searchReleaseDateFrom}
                  onChange={(e) => setSearchReleaseDateFrom(e.target.value)}
                />
                <span className=''>〜</span>
                <input
                  type="date"
                  className='w-[180px] ml-[10px] h-[35px] shadow-sm'
                  value={searchReleaseDateTill}
                  onChange={(e) => setSearchReleaseDateTill(e.target.value)}
                />
              </div>
              <div className='mb-[25px] mx-[20px] flex items-center'>
                <label className='text-[14px] mb-[5px] w-[170px]'><p>クチコミ投稿(編集)日/</p><p>お気に入り登録日</p></label>
                <input
                  type="date"
                  className='w-[180px] mr-[10px] h-[35px] shadow-sm'
                  value={searchReviewUpdatedOrBookmarkedFrom}
                  onChange={(e) => setSearchReviewUpdatedOrBookmarkedFrom(e.target.value)}
                />
                <span className=''>〜</span>
                <input
                  type="date"
                  className='w-[180px] ml-[10px] h-[35px] shadow-sm'
                  value={searchReviewUpdatedOrBookmarkedTill}
                  onChange={(e) => setSearchReviewUpdatedOrBookmarkedTill(e.target.value)}
                />
              </div>
              <div className="px-4 py-1 flex justify-center">
                <button
                  className="mt-3 w-[200px] h-[45px] inline-flex justify-center rounded-[30px] bg-blue-400 shadow-sm px-4 py-2 text-white hover:bg-blue-500 font-bold duration-300 mr-[20px]">
                  この条件で検索する
                </button>
                <button
                  type="button"
                  className="mt-3 w-[200px] h-[45px] inline-flex justify-center rounded-[30px] border border-blue-400 shadow-sm px-4 py-2 text-blue-400 hover:bg-blue-400 hover:text-white font-bold duration-300"
                  onClick={handleCloseModal}>
                  戻る
                </button>
              </div>
            </div>
          </form>
        )
      }
    </>
  );
}
