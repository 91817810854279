import { Auth } from '@aws-amplify/auth'
import { CognitoUser } from "amazon-cognito-identity-js";

console.log(`process.env.APP_ENV:${process.env.REACT_APP_ENV}`)

const authConfig = process.env.REACT_APP_ENV === 'production' ? {
  userPoolId: 'ap-northeast-1_sA5AVYbrp',
  userPoolWebClientId: '569pup3b0n3tdasfaq1qiu7ju5',
} : {
  userPoolId: 'ap-northeast-1_dRjET9hVi',
  userPoolWebClientId: '6bjdqmob939qqng35tvpduqore',
}

// AWS Account: Alleeks cosmebi-dev (223588641751)
// UserPool: COSMEbiUserPool
Auth.configure({
  region: 'ap-northeast-1',
  ...authConfig,
})

export type CognitoUserWithChallenge = CognitoUser & {
  attributes?: { // 無いときがある(初めてのログイン時)
    email: string;
    sub: string;
  },
  challengeName?: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | 'SELECT_MFA_TYPE' | 'MFA_SETUP' | 'PASSWORD_VERIFIER' | 'CUSTOM_CHALLENGE' | 'DEVICE_SRP_AUTH' | 'DEVICE_PASSWORD_VERIFIER' | 'ADMIN_NO_SRP_AUTH' | 'NEW_PASSWORD_REQUIRED'
  challengeParam?: any
}

type SignInError = 'INCORRECT_PASSWORD'
type CompleteNewPasswordError = 'INVALID_PASSWORD'

export const currentUserPoolUser = async (): Promise<CognitoUser|null> => {
  try {
    return await Auth.currentAuthenticatedUser()
  } catch {
    return null
  }
}

export const signIn = async (username: string, password: string): Promise<CognitoUserWithChallenge|SignInError> => {
  try {
    const user = await Auth.signIn({ username, password }) as CognitoUserWithChallenge
    return user
  } catch {
    return 'INCORRECT_PASSWORD'
  }
}

export const completeNewPassword = async (user: CognitoUser, password: string): Promise<CognitoUserWithChallenge|CompleteNewPasswordError> => {
  try {
    const result = await Auth.completeNewPassword(user, password) as CognitoUserWithChallenge
    return result
  } catch(e: any) {
    if (e.name === 'InvalidPasswordException') {
      return 'INVALID_PASSWORD'
    } else {
      throw(e)
    }
  }
}

export const confirmSignIn = (user: CognitoUser, code: string) => {
  return Auth.confirmSignIn(user, code, 'SMS_MFA')
}
