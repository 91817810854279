import { ProductRecommend } from "../../types/typescript-axios";
import { ElementInList } from "../../components/parts/ElementInList";
import { toDateString } from "../../utils/toDateString";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useManager } from "../../components/contexts/ManagerProvider";
import { FetchListView } from "../../components/templates/FetchListView";
import { ConfirmModal } from "../../components/parts/ConfirmModal";
import { authorizedRequest } from "../../utils/api-request";
import moment from "moment";

function ListElement({ elem }: { elem: ProductRecommend }) {
  const manager = useManager()
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [applyDate, setApplyDate] = useState<string>(moment(elem.applyDate).format('YYYY-MM-DD'))

  useEffect(() => {
    console.log(applyDate)
  }, [applyDate])

  async function update() {
    const response = await authorizedRequest(manager, 'UpdateProductRecommend', {
      id: elem.id,
      applyDate,
    })
    if (response.status === 200) {
      window.location.reload()
    }
  }

  async function destroy() {
    await authorizedRequest(manager, 'DestroyProductRecommend', { id: elem.id })
    window.location.reload()
  }

  return (
    <ElementInList>
      <span className='inline-block w-[80px]'>{elem.product.id}</span>
      <Link to={`/products/${elem.product.id}`} className='inline-block ml-[12px] w-[400px] list-link break-all'>
        { elem.product.name }
      </Link>
      {
        isEdit ? (
          <>
            <input type='date' value={applyDate} onChange={(e) => setApplyDate(e.target.value)} />
            <div className='flex items-center'>
              <button className='small-btn ml-2' onClick={update}>決定</button>
              <button className='small-btn ml-4 gray-btn' onClick={() => setIsEdit(false)}>キャンセル</button>
            </div>
          </>
        ) : (
          <>
            <span className='flex flex-col items-end w-[100px]'>
              <span>{ toDateString(elem.applyDate)}</span>
            </span>
            <span className='flex-1' />
            <div>
              <button className='gray h-[42px] w-[68px]' onClick={() => setIsEdit(true)}>編集</button>
              <button className='small-btn red-btn ml-4' onClick={() => setShowConfirmDeleteModal(true)}>削除</button>
            </div>
          </>
        )
      }
      {
        showConfirmDeleteModal && (
          <ConfirmModal
            title='オススメ商品から取り除く'
            content='本当に取り除きますか？'
            okLogic={destroy}
            cancelLogic={() => setShowConfirmDeleteModal(false)}
          />
        )
      }

    </ElementInList>
  )
}

export const RecommendList: React.FunctionComponent = () => {
  const manager = useManager()
  const navigate = useNavigate()

  async function fetch({ query, page, per }: { query: string|null, page: number|null, per: number|null}): Promise<[ProductRecommend[], number]> {
    const response = await authorizedRequest(manager, 'IndexProductRecommends', {
      ...(query && { query }),
      ...(page && { page }),
      ...(per && { per }),
    })
    if (response.status !== 200) return [[], 0]

    const { data } = response
    return [data.productRecommends, data.count]
  }

  return (
    <FetchListView<ProductRecommend>
      title='オススメ商品一覧'
      fetch={fetch}
      create={() => navigate('/products/recommends/new')}
      ListElemComp={ListElement}
      hasSearch={false}
    />
  )
}
