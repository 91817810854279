import { FormEvent, useState, useEffect } from "react";
import { Ad, AdType, CreateAdRequestData, UpdateAdRequestData, } from "../../../types/typescript-axios";
import { LeftLabel } from "../../../components/molecules/LeftLabel";
import { ImageInput } from "../../../components/parts/ImageInput";
import { objectKeys } from "../../../lib/object-keys";
import { toDateString } from "../../../utils/toDateString";
import { AdTypeName } from "../../../constants/ad-type-name";

type AdFormInput =
  { submit: (params: CreateAdRequestData) => void }
  | { ad: Ad, submit: (params: UpdateAdRequestData) => void }
export const AdForm = (props: AdFormInput) => {
  const ad = 'ad' in props ? props.ad : undefined

  const [name, setName] = useState<string>(ad?.name || '')
  const [linkUrl, setLinkUrl] = useState<string>(ad?.linkUrl || '')
  const [pcImage, setPcImage] = useState<string>()
  const [spImage, setSpImage] = useState<string>()
  const [adType, setAdType] = useState<string>(ad?.adType || 'TOP')
  const [adTop, setAdTop] = useState<boolean>(true)
  const [enabled, setEnabled] = useState<string>(ad?.enabled === true ? 'true' : 'false')
  const [publishFrom, setPublishFrom] = useState<string>(ad?.publishFrom ? toDateString(ad.publishFrom) : '')
  const [publishTill, setPublishTill] = useState<string>(ad?.publishTill ? toDateString(ad.publishTill) : '')

  const [errorStatement, setErrorStatement] = useState<string>()

  useEffect(() => {
    if(adType !== 'TOP') setAdTop(false)
  }, [adType])

  useEffect(() => {
    if(adType === 'TOP') setSpImage(pcImage)
  }, [pcImage])

  async function onSubmit(e: FormEvent) {
    e.preventDefault()

    const enabledValue = enabled === 'true'
    const data = {
      name,
      adType: adType as AdType,
      enabled: enabledValue,
      linkUrl, pcImage, spImage,
      publishFrom, publishTill,
    }
    if ('ad' in props) {
      props.submit({id: props.ad.id, ...data})
    } else {
      if (!pcImage) return setErrorStatement('PC用画像を入力してください')
      if (!spImage) return setErrorStatement('スマホ用画像を入力してください')

      props.submit({
        ...data,
        spImage,
        pcImage,
      })
    }
  }

  return (
    <form className='w-[800px]' onSubmit={onSubmit}>
      <LeftLabel title='名前' required={true}><input value={name} onChange={(e) => setName(e.target.value)}
                                                   required={true}/></LeftLabel>
      <LeftLabel title='リンク先' required={true}><input className='w-[400px]' value={linkUrl}
                                                     onChange={(e) => setLinkUrl(e.target.value)}
                                                     required={true}/></LeftLabel>
      <LeftLabel title='広告種別' required={true}>
        <select value={adType} onChange={(e) => setAdType(e.target.value)}>
          {
            objectKeys(AdTypeName).map(adType => (
              <option value={adType}>{AdTypeName[adType]}</option>
            ))
          }
        </select>
      </LeftLabel>
      <LeftLabel title='PC用画像' required={true}>
        {pcImage && <img className='mb-4 w-[160px]' src={pcImage}/>}
        {!pcImage && ad?.pcImageUrl && <img className='mb-4 w-[160px]' src={ad.pcImageUrl}/>}
        <ImageInput onFileInput={setPcImage}/>
      </LeftLabel>
      {
        !adTop && (
          <LeftLabel title='スマホ用画像' required={true}>
            {spImage && <img className='mb-4 w-[160px]' src={spImage}/>}
            {!spImage && ad?.spImageUrl && <img className='mb-4 w-[160px]' src={ad.spImageUrl}/>}
            <ImageInput onFileInput={setSpImage}/>
          </LeftLabel>
        )
      }
      <LeftLabel title='有効/無効' required={true}>
        <select value={enabled} onChange={(e) => setEnabled(e.target.value)}>
          <option value='false'>無効</option>
          <option value='true'>有効</option>
        </select>
      </LeftLabel>
      <LeftLabel title='表示期間'>
        <input
          required={enabled === 'true'}
          type='date'
          className='w-[160px]'
          value={publishFrom}
          onChange={(e) => setPublishFrom(e.target.value)}
        />
        <span className='px-8'>〜</span>
        <input
          required={enabled === 'true'}
          type='date'
          className='w-[160px]'
          value={publishTill}
          onChange={(e) => setPublishTill(e.target.value)}
        />
      </LeftLabel>
      {
        errorStatement && (
          <p className='py-16 text-red-500'>{ errorStatement }</p>
        )
      }
      <div className='mt-16 flex'>
        <button>{ad ? '更新' : '作成'}</button>
      </div>
    </form>
  )
}
