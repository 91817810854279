import React, { useContext, useEffect, useState } from "react";
import { CognitoUserWithChallenge, currentUserPoolUser } from "../../config/cognito-authenticator";
import { authorizedRequest } from "../../utils/api-request";
import { Manager } from "../../types/typescript-axios";

type ManagerContextType = CognitoUserWithChallenge & Pick<Manager, 'id' | 'authority' | 'personalDataFlg'> & {
  isApiChecked: boolean
}
export const ManagerContext = React.createContext<[
  ManagerContextType|null,
  (manager: CognitoUserWithChallenge|null) => Promise<void>,
  () => void
]>([null, (_) => new Promise((res) => res), () => {}])

export const ManagerProvider: React.FunctionComponent = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [manager, setManager] = useState<ManagerContextType|null>(null)

  useEffect(() => {
    setCurrentManager()
  }, [])

  async function setCurrentManager() {
    const manager = await currentUserPoolUser() as ManagerContextType
    if (!manager) return setIsLoading(false)

    setManagerContext(manager)
  }

  function signOut() {
    if (!manager) return

    return new Promise((resolve) => {
      manager.signOut(() => {
        setManager(null)
        resolve(null)
      })
    })
  }

  async function setManagerContext(manager: CognitoUserWithChallenge | null) {
    if (!manager) return setManager(null)

    const managerContextType = manager as ManagerContextType

    const response = await authorizedRequest(manager, 'ShowMe')
    if (response.status === 200) {
      managerContextType.authority = response.data.manager.authority
      managerContextType.personalDataFlg = response.data.manager.personalDataFlg
      managerContextType.isApiChecked = true
    }

    setManager(managerContextType)
    setIsLoading(false)
  }

  if (isLoading) return (<div></div>)

  return (
    <ManagerContext.Provider value={[manager, setManagerContext, signOut]}>
      { children }
    </ManagerContext.Provider>
  )
}

export function useManager(): ManagerContextType {
  const [manager] = useContext(ManagerContext)
  return manager as ManagerContextType
}
