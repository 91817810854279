import React, { ReactNode } from "react";

export const ConfirmModal = (
  { title, content, okLogic, okText = 'OK', cancelLogic }:
    { title: string, content: ReactNode, okText?: string, okLogic: () => void, cancelLogic: () => void }
) => {
  return (
    <div className='absolute top-0 left-0 flex justify-center items-center h-screen w-screen' style={{ backgroundColor: 'rgba(0, 0, 0, .5)'}}>
      <div
        className='flex flex-col rounded-2xl bg-white min-h-[260px] min-w-[360px]
        pt-24 px-24 pb-12
        '
      >
        <h2>{ title }</h2>
        {
          typeof content === 'string' ? (
            <div className='mt-8'>{ content }</div>
          ) : content
        }
        <div className='flex flex-1 justify-center items-end gap-[8px] p-4'>
          <button className='small-btn gray-btn min-w-[120px]' onClick={cancelLogic}>キャンセル</button>
          <button className='small-btn min-w-[120px]' onClick={okLogic}>{ okText }</button>
        </div>
      </div>
    </div>
  )
}