import { useManager } from "../../components/contexts/ManagerProvider";
import { useNavigate } from "react-router-dom";
import { ProductForm, ProductFormData } from "../../components/templates/products/ProductForm";
import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { authorizedRequest } from "../../utils/api-request";

export const NewProduct = () => {
  const manager = useManager()
  const navigate = useNavigate()

  async function submit(data: ProductFormData) {
    await authorizedRequest(manager, 'CreateProduct', {
      ...data,
      productImages: data.productImages.map(i => 'image' in i ? i.image : null).filter(x => x) as string[]
    })
    navigate('/products/list')
  }

  return (
    <SidebarLayout>
      <h1>商品作成</h1>
      <ProductForm submit={submit} />
    </SidebarLayout>
  )
}
