import React, { useEffect, useRef } from "react";

export const AutoFocusDiv = ({
                               onBlur,
                               className,
                               children,
                               ...props
                             }: React.HTMLAttributes<HTMLDivElement>) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) ref.current.focus()
  }, [ref])

  return (
    <div className={className + ' outline-0'} ref={ref} tabIndex={100} onBlur={onBlur} {...props}>
      {children}
    </div>
  )
}