import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { useManager } from "../../components/contexts/ManagerProvider";
import { useNavigate } from "react-router-dom";
import { AdForm } from "./common/AdForm";
import { CreateAdRequestData } from "../../types/typescript-axios";
import { authorizedRequest } from "../../utils/api-request";

export const NewAd = () => {
  const manager = useManager()
  const navigate = useNavigate()

  async function submit(params: CreateAdRequestData) {
    await authorizedRequest(manager, 'CreateAd', params)
    navigate('/ads')
  }

  return (
    <SidebarLayout>
      <div className='p-4 w-[800px]'>
        <h1>広告新規作成</h1>
        <AdForm submit={submit} />
      </div>
    </SidebarLayout>
  )
}
