type StringOrNumber = string | number | undefined

export const downloadCsv = (array: StringOrNumber[][], filename: string) => {
  const csv = array.map(line =>
    line.map(word =>
      !!word ? `"${String(word).replace(/"/g, '""')}"` : '').join(',')
  ).join('\n')

  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export const downloadString = (data: string, filename: string) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
