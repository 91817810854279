import { AxiosResponse } from 'axios'
import { AuthorizedRequest, AuthorizedRequestApi, AuthorizedResponse } from "../types/typescript-axios";
import { apiConfiguration } from "../config/api-configuration";
import { CognitoUserWithChallenge } from "../config/cognito-authenticator";

type ExtractData<T, X> = X extends { type: T, data: infer U } ? U : never

type AuthorizedRequestType = AuthorizedRequest['type']

export async function authorizedRequest<T extends AuthorizedRequestType>(manager: CognitoUserWithChallenge|null, type: T, data?: ExtractData<T, AuthorizedRequest>): Promise<HandleErrorResponse<ExtractData<T, AuthorizedResponse>>> {
  const api = new AuthorizedRequestApi(apiConfiguration(manager))
  // @ts-ignore
  return handleApi(api.authorizedRequest({type, ...(data && { data })})) as any as HandleErrorResponse<Res<T>>
}

export type HandleErrorResponse<T> =  {
  status: 200
  data: T
} | {
  status: 422
  data: {
    type: string
    code: string
    params: {
      [key: string]: string[]
    }
  }
}
export async function handleApi<T>(result: Promise<AxiosResponse<T>>): Promise<HandleErrorResponse<T>> {
  try {
    const response = await result
    return {
      status: 200,
      data: response.data,
    }
  } catch(e: any) {
    console.error(e)
    console.log(JSON.stringify(e.response.data))
    return {
      status: e.response.status,
      data: e.response.data,
    }
  }
}
