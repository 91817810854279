import { ReactElement } from "react";

export const Modal = ({ children, hide }: { children: ReactElement, hide: () => void }) => (
  <div
    className='fixed top-0 left-0 flex items-center justify-center w-screen h-screen'
    style={{backgroundColor: 'rgba(0, 0, 0, .5)'}}
    onClick={hide}
  >
    <div className='flex flex-col bg-white p-10 rounded-xl' onClick={(e) => e.stopPropagation()}>
      { children }
    </div>
  </div>
)
