import { CustomerStatus, OfficialStatus } from "../types/typescript-axios";

export const officialStatusToString = (status: OfficialStatus | undefined) => {
  return status === OfficialStatus.NUMBER_1 ? '一般会員'
    : status === OfficialStatus.NUMBER_2 ? 'オフィシャルメンバー招待中'
    : status === OfficialStatus.NUMBER_3 ? 'オフィシャルメンバー'
    : status === OfficialStatus.NUMBER_4 ? 'アンバサダー招待中'
    : status === OfficialStatus.NUMBER_5 ? 'アンバサダー'
    : undefined
}
