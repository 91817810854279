import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { CustomerForm, CustomerFormData } from "../../components/templates/customers/CustomerForm";
import { useEffect, useState } from "react";
import { Interest, Pref, Skin } from "../../types/typescript-axios";
import { useManager } from "../../components/contexts/ManagerProvider";
import { useNavigate } from "react-router-dom";
import { authorizedRequest } from "../../utils/api-request";

export const NewCustomer = () => {
  const manager = useManager()
  const navigate = useNavigate()
  const [prefList, setPrefList] = useState<Pref[]>()
  const [skinList, setSkinList] = useState<Skin[]>()
  const [interestList, setInterestList] = useState<Interest[]>()

  useEffect(() => {
    async function fetchPrefs() {
      const response = await authorizedRequest(manager, 'IndexPrefs')
      if (response.status !== 200) return

      const { data: { prefs }} = response
      setPrefList(prefs)
    }
    async function fetchSkins() {
      const response = await authorizedRequest(manager, 'IndexSkins')
      if (response.status !== 200) return

      const { data: { skins }} = response
      setSkinList(skins)
    }
    async function fetchInterests() {
      const response = await authorizedRequest(manager, 'IndexInterests')
      if (response.status !== 200) return

      const { data: { interests }} = response
      setInterestList(interests)
    }
    fetchPrefs()
    fetchSkins()
    fetchInterests()
  }, [])

  async function submit(data: CustomerFormData) {
    const response = await authorizedRequest(manager, 'CreateCustomer', data)
    if (response.status === 200) {
      navigate('/customers/list')
    } else {
      return response.data.params
    }
  }

  return (
    <SidebarLayout>
      <CustomerForm prefList={prefList} skinList={skinList} interestList={interestList} create={submit} />
    </SidebarLayout>
  )
}
