import { CSSProperties } from "react";

type Props = {
  src: string | undefined
  alt: string
  className: string
  style?: CSSProperties
}
export const ImageWithCoverLayout = ({ src, alt, className, style }: Props) => {
  return (
    <div className={'relative ' + className} {...(style && { style })}>
      { src && <img src={src} alt={alt} /> }
    </div>
  )
}