import { SidebarLayout } from "../components/layout/SidebarLayout";
import React, { useEffect, useState } from "react";
import {
  AnalysisProductReviewsSubject,
  AnalysisResponse,
  Product,
  UserContentStatus,
} from "../types/typescript-axios";
import { useManager } from "../components/contexts/ManagerProvider";
import { ElementInList } from "../components/parts/ElementInList";
import { AutoFocusDiv } from "../components/shared/parts/AutoFocusDiv";
import { OpenRankingAnalysisModal } from "../components/templates/ranking-analysis/RankingAnalysisModal";
import { authorizedRequest } from "../utils/api-request";
import { Link, useSearchParams } from "react-router-dom";
import { useCategories } from "../components/contexts/category-provider";
import { downloadCsv } from "../lib/download-csv";

type RankingTargetKeys = 'review' | 'favorite'

let RankingTargets: { [key in RankingTargetKeys]: string };
RankingTargets = {
  [AnalysisProductReviewsSubject.Review]: 'クチコミ順位',
  [AnalysisProductReviewsSubject.Favorite]: 'お気に入り順位',
} as const;

export function RankingAnalysis() {
  const manager = useManager()
  const [searchParams] = useSearchParams()
  const [analyses, setAnalyses] = useState<Array<Product & AnalysisResponse>>([])
  const [analyzeTarget, setAnalyzeTarget] = useState<AnalysisProductReviewsSubject>(AnalysisProductReviewsSubject.Review)
  const [showTargetCandidates, setShowTargetCandidates] = useState(false)

  useEffect(() => {
    if (!manager) return

    const fetch = async () => {
      const channels = searchParams.get('channel')?.split(',') || undefined
      const statuses = searchParams.get('status')?.split(',').map(id => Number(id)) || undefined
      const categoryId = searchParams.get('category-id')
      const productName = searchParams.get('product-name')
      const productId = searchParams.get('product-id')
      const releaseFrom = searchParams.get('release-from')
      const releaseTill = searchParams.get('release-till')
      const postFrom = searchParams.get('post-from')
      const postTill = searchParams.get('post-till')

      const response = await authorizedRequest(manager, 'AnalysisProductReviews', {
        subject: analyzeTarget,
        ...(channels && { channels }),
        ...(statuses && { statuses: statuses as UserContentStatus[]}),
        ...(categoryId && { categoryId: Number(categoryId) }),
        ...(productName && { productName }),
        ...(productId && { productId: Number(productId) }),
        ...(releaseFrom && { releaseDateFrom: releaseFrom }),
        ...(releaseTill && { releaseDateTill: releaseTill }),
        ...(postFrom && { applyDateFrom: postFrom }),
        ...(postTill && { applyDateTill: postTill }),
      })
      if (response.status === 200) {
        setAnalyses(response.data.products)
      }
    }
    fetch()
  }, [searchParams, analyzeTarget, manager])

  function toggleAnalysisTarget() {
    setAnalyzeTarget(
      analyzeTarget === AnalysisProductReviewsSubject.Review ?
        AnalysisProductReviewsSubject.Favorite : AnalysisProductReviewsSubject.Review
    )
  }

  async function download() {
    const response = await authorizedRequest(manager, 'AnalysisProductReviews', {
      subject: analyzeTarget,
    })
    if (response.status !== 200) return

    downloadCsv(analyses.map(product => [
      product.ranking, product.id, product.name, product.reviewCount, product.reviewCount, product.reviewAverage
    ]), 'ランキング解析.csv')
  }

  return (
    <SidebarLayout>
      <div className='p-[16px]'>
        <h1>ランキング解析</h1>
        <div className="flex justify-between">
          <div className='relative'>
            <button className='small-btn white-btn' onClick={() => setShowTargetCandidates(true)}>
              {RankingTargets[analyzeTarget]}
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
              </svg>
            </button>
            {
              showTargetCandidates && <AutoFocusDiv
                className='
                    flex justify-center items-center absolute top-[40px] left-0
                    bg-white h-[40px] w-[160px] border border-cosmebi-pink text-cosmebi-pink
                    hover:bg-pink-100
                    cursor-pointer
                    '
                onBlur={() => setShowTargetCandidates(false)}
                onClick={() => {
                  toggleAnalysisTarget()
                  setShowTargetCandidates(false)
                }}
              >
                {
                  RankingTargets[
                    analyzeTarget === AnalysisProductReviewsSubject.Review ?
                      AnalysisProductReviewsSubject.Favorite : AnalysisProductReviewsSubject.Review
                    ]
                }
              </AutoFocusDiv>
            }
            <button
              className='blue-btn w-[150px] h-[42px] ml-[8px]'
              onClick={download}
            >
              CSVダウンロード
            </button>
          </div>
          <OpenRankingAnalysisModal />
        </div>
        <div className='flex flex-col gap-4 mt-8'>
          <ElementInList>
            <span className='w-[100px]'>順位</span>
            <span className='flex-1'>商品名</span>
            <span className='w-[120px] flex justify-center'>クチコミ数</span>
            <span className='w-[120px] flex justify-center'>お気に入り数</span>
            <span className='w-[120px] flex justify-center'>平均評価</span>
          </ElementInList>
          {
            analyses.map((product) => (
              <ElementInList key={product.id}>
                <span className='w-[100px]'>{ product.ranking }位</span>
                <Link to={`/products/${product.id}`} className='flex-1 list-link'>{product.name}</Link>
                <span className='w-[120px] flex justify-center'>{!!product.reviewCount ? product.reviewCount : 0}</span>
                <span className='w-[120px] flex justify-center'>{product.favoriteCount}</span>
                <span className='w-[120px] flex justify-center'>{!!product.reviewAverage ? Math.round(product.reviewAverage* 100) / 100 : 'なし'}</span>
              </ElementInList>
            ))
          }
        </div>
      </div>
    </SidebarLayout>
  )
}
