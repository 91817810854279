import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { useManager } from "../../components/contexts/ManagerProvider";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Qa, UserContentStatus } from "../../types/typescript-axios";
import { LeftLabel } from "../../components/molecules/LeftLabel";
import moment from "moment";
import { authorizedRequest } from "../../utils/api-request";

export const QandADetail = () => {
  const manager = useManager()
  const { id } = useParams()
  const [qa, setQa] = useState<Qa>()
  const [status, setStatus] = useState<UserContentStatus>(1)
  const [title, setTitle] = useState<string>('')
  const [nickname, setNickname] = useState<string>('')
  const [comment, setComment] = useState<string>('')

  useEffect(() => {
    async function fetchQa() {
      if (!id) return

      const response = await authorizedRequest(manager, 'ShowQa', { id: +id })
      if (response.status !== 200) return

      const { data: { qa }} = response

      console.log(qa)
      setQa(qa)
      setStatus(qa.statusId); setTitle(qa.title);
      setNickname(qa.nicname); setComment(qa.comment);
    }
    fetchQa()
  }, [])

  async function updateQa() {
    if (!id) return

    await authorizedRequest(manager, 'UpdateQa', {
      id: +id, title, comment,
      nicname: nickname,
      statusId: status,
    })
    window.location.reload()
  }

  return (
    <SidebarLayout>
      <h1>Q&A詳細</h1>
      <div>
        <LeftLabel title='ID'>{qa?.id}</LeftLabel>
        <LeftLabel title='作成日時'>{qa && moment(qa?.createDate).format('YYYY-MM-DD HH:mm')}</LeftLabel>
        <LeftLabel title='ステータス'>
          <select value={status} onChange={(e) => setStatus(+e.target.value as UserContentStatus)}>
            <option value={1}>公開</option>
            <option value={2}>非公開</option>
            <option value={8}>下書き</option>
          </select>
        </LeftLabel>
        <LeftLabel title='タイトル'>
          <input value={title} onChange={(e) => setTitle(e.target.value)} />
        </LeftLabel>
        <LeftLabel title='ニックネーム'>
          <input value={nickname} onChange={(e) => setNickname(e.target.value)} />
        </LeftLabel>
        <LeftLabel title='コメント'>
          <textarea value={comment} onChange={(e) => setComment(e.target.value)} />
        </LeftLabel>

        <div className='flex justify-center mt-16'>
          <button onClick={updateQa}>登録</button>
        </div>
      </div>
    </SidebarLayout>
  )
}
