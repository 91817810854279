import React, { useState } from "react";
import { FetchListView } from "../../components/templates/FetchListView";
import { useManager } from "../../components/contexts/ManagerProvider";
import { ElementInList } from "../../components/parts/ElementInList";
import { ConfirmModal } from "../../components/parts/ConfirmModal";
import { Brand } from "../../types/typescript-axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { authorizedRequest } from "../../utils/api-request";
import { downloadCsv } from "../../lib/download-csv";

function ListElement({elem}: { elem: Brand }) {
    const manager = useManager()
  
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false)
  
    async function destroy() {
      await authorizedRequest(manager, 'DestroyBrand', {
        id: elem.id,
      })
      window.location.reload()
    }
  
    return (
      <ElementInList>
        <div className='flex flex-1 items-center justify-between'>
          <div>
            <span>{ elem.id }</span>
            <Link to={`/brands/${elem.id}`}>
              <span className='ml-4 inline-block w-[320px] list-link'>{ elem.name }</span>
            </Link>
            <span className='ml-4'>{elem.maker.name}</span>
          </div>
          <div>
            <button className='small-btn red-btn ml-4' onClick={() => setShowConfirmDeleteModal(true)}>削除</button>
          </div>
        </div>
        {
          showConfirmDeleteModal && (
            <ConfirmModal
              title='ブランドの削除'
              content='削除したら戻すことはできません。本当に削除しますか？'
              okLogic={destroy}
              cancelLogic={() => setShowConfirmDeleteModal(false)}
            />
          )
        }
      </ElementInList>
    )
  }

export const BrandList: React.FunctionComponent = () => {
  const manager = useManager()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  async function fetch({
                         query,
                         page,
                         per
                       }: { query: string | null, page: number | null, per: number | null }): Promise<[Brand[], number]> {
    const response = await fetchData({ query, page, per })
    if (response.status !== 200) return [[], 0]

    const { data } = response
    return [data.brands, data.count]
  }

  function create() {
    navigate('/brands/new')
  }

  async function csvDownload() {
    const query = searchParams.get('query')
    const response = await fetchData(({page: 1, per: 5000, query}))
    if (response.status !== 200) return

    const {brands} = response.data
    const headers = [
      'ID', '名前', 'メーカーID', 'メーカー名','チャネル'
    ]

    downloadCsv([
      headers,
      ...brands.map(brand => [
        brand.id, brand.name,
        brand.maker.id, brand.maker.name, brand.channel
      ])
    ], 'ブランド一覧.csv')
  }

  async function fetchData({query, page, per}: { query: string | null, page: number | null, per: number | null }) {
    return await authorizedRequest(manager, 'IndexBrands', {
      ...(query && {query}),
      ...(page && {page}),
      ...(per && {per}),
    })
  }

    return (
     <FetchListView<Brand>
        title='ブランド管理'
        fetch={fetch}
        create={create}
        ListElemComp={ListElement}
        headerElement={<HeaderElement csvDownload={csvDownload} />}
    />
  )
}

type Props = {
  csvDownload: () => void
}
export const HeaderElement = ({ csvDownload }: Props) => {
  return (
    <button
      className='blue-btn w-[150px] h-[45px] mr-8'
      onClick={csvDownload}
    >
      CSVダウンロード
    </button>
  )
}
