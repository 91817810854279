import icon1 from "../../assets/images/mypage/myicons/fd.png";
import icon2 from "../../assets/images/mypage/myicons/lady01.png";
import icon3 from "../../assets/images/mypage/myicons/lady02.png";
import icon4 from "../../assets/images/mypage/myicons/lady03.png";
import icon5 from "../../assets/images/mypage/myicons/lady04.png";
import icon6 from "../../assets/images/mypage/myicons/man.png";
import icon7 from "../../assets/images/mypage/myicons/lip.png";

export const CustomerIcons = [
  icon1, icon2, icon3, icon4, icon5, icon6, icon7
]
