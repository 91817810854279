import React from "react";
import { FetchListView } from "../../components/templates/FetchListView";
import { QaAnswer } from "../../types/typescript-axios";
import { useManager } from "../../components/contexts/ManagerProvider";
import { ElementInList } from "../../components/parts/ElementInList";
import { Link } from "react-router-dom";
import { authorizedRequest } from "../../utils/api-request";

function ListElement({ elem }: { elem: QaAnswer }) {
  return (
    <ElementInList>
      <span>{ elem.id}</span>
      <span className='ml-4'>{ elem.nicname }</span>
      <Link to={`/qa-answers/${elem.id}`} className='ml-4 list-link overflow-hidden text-ellipsis w-[400px] whitespace-nowrap'>{ elem.comment }</Link>
    </ElementInList>
  )
}

export const QaAnswerList: React.FunctionComponent = () => {
  const manager = useManager()

  async function fetch({ query, page, per }: { query: string|null, page: number|null, per: number|null}): Promise<[QaAnswer[], number]> {
    const response = await authorizedRequest(manager, 'IndexQaAnswers', {
      ...(query && { query }),
      ...(page && { page }),
      ...(per && { per }),
    })
    if (response.status !== 200) return [[], 0]

    const { data } = response
    return [data.qaAnswers, data.count]
  }
  return (
    <FetchListView<QaAnswer>
      fetch={fetch}
      ListElemComp={ListElement}
      title='Q&A回答管理'
    />
  )
}
