import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { ManagerAuthority, ManagerAuthorityEnum } from "../../types/typescript-axios/api";
import { FormEvent, useState } from "react";
import { useManager } from "../../components/contexts/ManagerProvider";
import { Link, useNavigate } from "react-router-dom";
import { authorizedRequest } from "../../utils/api-request";

export const NewManager = () => {
  const manager = useManager()
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>()
  const [authority, setAuthority] = useState<ManagerAuthority>(ManagerAuthority.Staff)
  const [personalDataFlg, setPersonalDataFlg] = useState<boolean>(false)

  const submit = async (e: FormEvent) => {
    e.preventDefault()
    if (!email || !authority) return

    await authorizedRequest(manager, 'CreateManager', {
      email,
      authority,
      personalDataFlg
    })
    navigate('/managers/list')
  }

  return (
    <SidebarLayout>
      <div className='py-4 px-4'>
        <h1>管理者作成</h1>

        <form className='w-fit' onSubmit={submit}>
          <div>
            <label className='inline-block w-[240px]'>メールアドレス</label>
            <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className='mt-8'>
            <label className='inline-block w-[240px]'>権限</label>
            <select value={authority}
                    onChange={(e) => setAuthority(e.target.value === ManagerAuthority.Staff ? ManagerAuthority.Staff : e.target.value === ManagerAuthority.Admin ? ManagerAuthority.Admin : ManagerAuthority.Guest)}
            >
              <option value={ManagerAuthorityEnum.Guest}>ゲスト</option>
              <option value={ManagerAuthorityEnum.Staff}>スタッフ</option>
              <option value={ManagerAuthorityEnum.Admin}>管理者</option>
            </select>
          </div>

          <div>
            <label className='inline-block w-[240px]'>個人情報取得権限</label>
            <input
              id='is-personal'
              type='radio'
              className='w-[24px]'
              checked={personalDataFlg}
              onChange={() => setPersonalDataFlg(true)}
            />
            <label htmlFor='is-Personal'>
              あり
            </label>
            <span className='inline-block w-[24px]' />
            <input
              id='not-personal'
              type='radio'
              className='w-[24px]'
              checked={!personalDataFlg}
              onChange={() => setPersonalDataFlg(false)}
            />
            <label htmlFor='not-Personal'>
              なし
            </label>
          </div>

          <div className='mt-24 flex justify-center gap-x-16'>
            <Link to='/managers/list'>
              <button type='button' className='gray-btn'>一覧へ</button>
            </Link>
            <button>作成</button>
          </div>
        </form>
      </div>
    </SidebarLayout>
  )
}
