export function TranslateProductReviewRecommendRevel({ level }: { level : number }) {
  const Star = (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="18px" viewBox="0 0 24 24"
         width="18px" fill="#000000">
      <g>
        <rect fill="none" height="18" width="18" x="0"/>
        <polygon points="14.43,10 12,2 9.57,10 2,10 8.18,14.41 5.83,22 12,17.31 18.18,22 15.83,14.41 22,10"/>
      </g>
    </svg>
  )
  return (
    <span className='inline-flex items-center'>
      {
        [...new Array(level)].map(() => Star)
      }
    </span>
  )
}