import { FormEvent, ReactNode, useState } from "react";
import {
  Customer,
  CustomerStatus,
  Interest,
  MailMagazineFlg,
  OfficialStatus,
  Pref,
  Skin
} from "../../../types/typescript-axios";
import { ChooseMyimage } from "../../parts/ChooseMyImage";

export type CustomerFormData = {
  customerStatusId: CustomerStatus
  officialStatusId: OfficialStatus
  password: string
  nicname: string
  email: string
  name01: string
  name02: string
  kana01: string
  kana02: string
  postalCode: string
  addr01: string
  addr02: string
  prefId: number
  phoneNumber: string
  skinIds: number[]
  interestIds: number[]
  birth: string
  profile: string
  twitter: string
  facebook: string
  instagtam: string
  blog: string
  plgMailmagazineFlg: MailMagazineFlg
  note: string
  image: string
}

type Props = {
  customer: Customer
  prefList: Pref[] | undefined
  skinList: Skin[] | undefined
  interestList: Interest[] | undefined
  update: (data: Partial<CustomerFormData>) => Promise<{ [key: string]: string[] } | undefined>
} | {
  prefList: Pref[] | undefined
  skinList: Skin[] | undefined
  interestList: Interest[] | undefined
  create: (data: CustomerFormData) => Promise<{ [key: string]: string[] } | undefined>
}
export const CustomerForm = ({prefList, skinList, interestList, ...props}: Props) => {
  const customer = 'customer' in props ? props.customer : undefined

  const [customerStatusId, setCustomerStatusId] = useState<CustomerStatus>(customer?.customerStatusId || 1)
  const [officialStatusId, setOfficialStatusId] = useState<OfficialStatus>(customer?.officialStatusId || 1)
  const [nickname, setNickName] = useState<string>(customer?.nicname || '')
  const [email, setEmail] = useState<string>(customer?.email || '')
  const [name01, setName01] = useState<string>(customer?.name01 || '')
  const [name02, setName02] = useState<string>(customer?.name02 || '')
  const [kana01, setKana01] = useState<string>(customer?.kana01 || '')
  const [kana02, setKana02] = useState<string>(customer?.kana02 || '')
  const [postalCode, setPostalCode] = useState<string>(customer?.postalCode || '')
  const [addr01, setAddr01] = useState<string>(customer?.addr01 || '')
  const [addr02, setAddr02] = useState<string>(customer?.addr02 || '')
  const [prefId, setPrefId] = useState<number>(customer?.pref?.id || 1)
  const [phoneNumber, setPhoneNumber] = useState<string>(customer?.phoneNumber || '')
  const [skinIds, setSkinIds] = useState<number[]>(customer?.skins?.map(s => s.id) || [])
  const [interestIds, setInterestIds] = useState<number[]>(customer?.interests?.map(i => i.id) || [])
  const [birth, setBirth] = useState<string>(customer?.birth || '')
  const [profile, setProfile] = useState<string>(customer?.profile || '')
  const [twitter, setTwitter] = useState<string>(customer?.twitter || '')
  const [facebook, setFacebook] = useState<string>(customer?.facebook || '')
  const [instagtam, setInstagtam] = useState<string>(customer?.instagtam || '')
  const [blog, setBlog] = useState<string>(customer?.blog || '')
  const [plgMailmagazineFlg, setPlgMailManazineFlg] = useState<MailMagazineFlg>(customer?.plgMailmagazineFlg === undefined ? 1 : customer.plgMailmagazineFlg)
  const [note, setNote] = useState<string>(customer?.note || '')
  const [password, setPassword] = useState<string>('')
  const [image, setImage] = useState<string | undefined>(customer?.thumbnailUrl?.startsWith('__icon') ? undefined : customer?.thumbnailUrl)
  const [chooseImageIndex, setChooseImageIndex] = useState<number|undefined>(((thumbnailUrl) =>
      !thumbnailUrl ? undefined : thumbnailUrl?.startsWith('__icon') ? Number(thumbnailUrl.split(':')[1]) : 0
  )(customer?.thumbnailUrl))
  const [errorStatement, setErrorStatement] = useState<string>()

  async function execSubmit(e: FormEvent) {
    e.preventDefault()

    if (chooseImageIndex === undefined || (chooseImageIndex === 0 && image === undefined)) return setErrorStatement('画像を選択してください')
    const imageString = chooseImageIndex !== 0 ? `__icon:${chooseImageIndex}` : image!

    const commonParams = {
      customerStatusId, officialStatusId, nicname: nickname, email, name01, name02, kana01, kana02,
      postalCode, addr01, addr02, prefId, phoneNumber, skinIds, interestIds, birth, profile, twitter, facebook,
      instagtam, blog, plgMailmagazineFlg, note, password,
    }
    const result = await (() => {
      if ('customer' in props) {
        const updateImageString = imageString.startsWith('https') ? undefined : imageString

        return props.update({
          ...commonParams,
          ...(updateImageString &&  { image: updateImageString }),
        })
      } else {
        return props.create({
          ...commonParams,
          image: imageString,
        })
      }
    })()
    if (result) {
      const keys = Object.keys(result)
      if (keys.includes('customerNicname')) return setErrorStatement('すでに利用されているニックネームです')
      if (keys.includes('customerEmail')) return setErrorStatement('すでに利用されているメールアドレスです')
    }
  }

  function toggleSkinId(skinId: number) {
    setSkinIds(ids => {
      const contain = ids.includes(skinId)
      if (contain) {
        return ids.filter(id => id !== skinId)
      } else {
        return [...ids, skinId]
      }
    })
  }

  function toggleInterestId(interestId: number) {
    setInterestIds(ids => {
      const contain = ids.includes(interestId)
      if (contain) {
        return ids.filter(id => id !== interestId)
      } else {
        return [...ids, interestId]
      }
    })
  }

  return (
    <form className='py-4 px-4' onSubmit={execSubmit}>
      <h1>会員情報</h1>
      {
        customer && (
          <LabeledLineComp title='会員ID'>
            <div>{customer.id}</div>
          </LabeledLineComp>
        )
      }
      <LabeledLineComp title='ステータス' required={true}>
        <div>
          <div>
            <input id='customer-status-id-1' className='w-[24px]' type='radio' value={1}
                   checked={customerStatusId === 1} onChange={() => setCustomerStatusId(1)}/>
            <label htmlFor='customer-status-id-1'>仮会員</label>
          </div>
          <div>
            <input id='customer-status-id-2' className='w-[24px]' type='radio' value={2}
                   checked={customerStatusId === 2} onChange={() => setCustomerStatusId(2)}/>
            <label htmlFor='customer-status-id-2'>本会員</label>
          </div>
          <div>
            <input id='customer-status-id-3' className='w-[24px]' type='radio' value={3}
                   checked={customerStatusId === 3} onChange={() => setCustomerStatusId(3)}/>
            <label htmlFor='customer-status-id-3'>退会</label>
          </div>
        </div>
      </LabeledLineComp>
      <LabeledLineComp title='オフィシャル' required={true}>
        <div>
          <div>
            <input id='official-status-id-1' className='w-[24px]' type='radio' value={1}
                   checked={officialStatusId === 1} onChange={() => setOfficialStatusId(1)}/>
            <label htmlFor='official-status-id-1'>一般会員</label>
          </div>
          <div>
            <input id='official-status-id-2' className='w-[24px]' type='radio' value={2}
                   checked={officialStatusId === 2} onChange={() => setOfficialStatusId(2)}/>
            <label htmlFor='official-status-id-2'>オフィシャルメンバー招待中</label>
          </div>
          <div>
            <input id='official-status-id-3' className='w-[24px]' type='radio' value={3}
                   checked={officialStatusId === 3} onChange={() => setOfficialStatusId(3)}/>
            <label htmlFor='official-status-id-3'>オフィシャルメンバー</label>
          </div>
          <div>
            <input id='official-status-id-4' className='w-[24px]' type='radio' value={4}
                   checked={officialStatusId === 4} onChange={() => setOfficialStatusId(4)}/>
            <label htmlFor='official-status-id-4'>アンバサダー招待中</label>
          </div>
          <div>
            <input id='official-status-id-5' className='w-[24px]' type='radio' value={5}
                   checked={officialStatusId === 5} onChange={() => setOfficialStatusId(5)}/>
            <label htmlFor='official-status-id-5'>アンバサダー</label>
          </div>
        </div>
      </LabeledLineComp>
      {
        !customer && (
          <LabeledLineComp title='パスワード' required={true}>
            <input required type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
          </LabeledLineComp>
        )
      }
      <LabeledLineComp title='ニックネーム' required={true}>
        <input required type='text' value={nickname} onChange={(e) => setNickName(e.target.value)}/>
      </LabeledLineComp>
      <LabeledLineComp title='アイコン' required={true}>
        <ChooseMyimage image={image} setImage={setImage} chooseIndex={chooseImageIndex} setChooseIndex={setChooseImageIndex} />
      </LabeledLineComp>
      <LabeledLineComp title='メールアドレス' required={true}>
        <input required type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
      </LabeledLineComp>
      <LabeledLineComp title='お名前'>
        <input type='text' value={name01} onChange={(e) => setName01(e.target.value)}/>
        <input className='ml-4' type='text' value={name02} onChange={(e) => setName02(e.target.value)}/>
      </LabeledLineComp>
      <LabeledLineComp title='お名前(カナ)'>
        <input type='text' value={kana01} onChange={(e) => setKana01(e.target.value)}/>
        <input className='ml-4' type='text' value={kana02} onChange={(e) => setKana02(e.target.value)}/>
      </LabeledLineComp>
      <LabeledLineComp title='住所'>
        <div>〒<input type='text' value={postalCode} onChange={(e) => setPostalCode(e.target.value)}/></div>
        <div className='mt-4'>
          {
            prefList && prefId && (
              <select value={prefId} onChange={(e) => setPrefId(+e.target.value)}>
                {prefList.map(p => (<option value={p.id}>{p.name}</option>))}
              </select>
            )
          }
        </div>
        <div><input className='mt-4' type='text' value={addr01} onChange={(e) => setAddr01(e.target.value)}/></div>
        <div><input className='mt-4' type='text' value={addr02} onChange={(e) => setAddr02(e.target.value)}/></div>
      </LabeledLineComp>
      <LabeledLineComp title='電話番号'>
        <input type='text' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
      </LabeledLineComp>
      <LabeledLineComp title='肌質'>
        {
          skinList?.map(s => (
            <>
              <input id={`skin-${s.id}`} className='w-[24px]' type='checkbox' value={s.id}
                     checked={skinIds?.includes(s.id)}
                     onChange={(e) => toggleSkinId(Number(e.target.value))}
              />
              <label htmlFor={`skin-${s.id}`}>{s.name}</label>
            </>
          ))
        }
      </LabeledLineComp>
      <LabeledLineComp title='好み・関心'>
        {
          interestList?.map(i => (
            <>
              <input id={`interest-${i.id}`} className='w-[24px]' type='checkbox' value={i.id}
                     checked={interestIds?.includes(i.id)}
                     onChange={(e) => toggleInterestId(Number(e.target.value))}
              />
              <label htmlFor={`interest-${i.id}`}>{i.name}</label>
            </>
          ))
        }
      </LabeledLineComp>
      <LabeledLineComp title='誕生日' required={true}>
        <input
          type='date' value={birth}
          onChange={(e) => setBirth(e.target.value)}
          required
        />
      </LabeledLineComp>
      <LabeledLineComp title='プロフィール'>
        <textarea value={profile} onChange={(e) => setProfile(e.target.value)}/>
      </LabeledLineComp>
      <LabeledLineComp title='Twitterアカウント'>
        <input value={twitter} onChange={(e) => setTwitter(e.target.value)}/>
      </LabeledLineComp>
      <LabeledLineComp title='facebookアカウント'>
        <input value={facebook} onChange={(e) => setFacebook(e.target.value)}/>
      </LabeledLineComp>
      <LabeledLineComp title='instagramアカウント'>
        <input value={instagtam} onChange={(e) => setInstagtam(e.target.value)}/>
      </LabeledLineComp>
      <LabeledLineComp title='ブログURL'>
        <input className='w-[540px]' value={blog} onChange={(e) => setBlog(e.target.value)}/>
      </LabeledLineComp>
      <LabeledLineComp title='メールマガジン'>
        <div>
          <input id='mail-magazine-flg-1' className='w-[24px]' type='radio' value={1} checked={plgMailmagazineFlg === 1}
                 onChange={() => setPlgMailManazineFlg(1)}/>
          <label htmlFor='mail-magazine-flg-1'>受け取る</label>
        </div>
        <div>
          <input id='mail-magazine-flg-0' className='w-[24px]' type='radio' value={2} checked={plgMailmagazineFlg === 0}
                 onChange={() => setPlgMailManazineFlg(0)}/>
          <label htmlFor='mail-magazine-flg-0'>受け取らない</label>
        </div>
      </LabeledLineComp>
      <LabeledLineComp title='管理側メモ'>
        <textarea value={note} onChange={(e) => setNote(e.target.value)}/>
      </LabeledLineComp>

      {
        errorStatement && (
          <p className='my-12 text-center text-red-500 font-bold'>{errorStatement}</p>
        )
      }
      <div className='flex justify-center'>
        <button>登録</button>
      </div>
    </form>
  )
}

const LabeledLineComp = ({
                           title,
                           children,
                           required = false
                         }: { title: string, children: ReactNode, required?: boolean }) => {
  return (
    <div className='flex mb-[32px]'>
      <div className='w-[300px]'>
        <label>{title}</label>
        {required && (
          <span className='ml-2 text-[12px] font-bold text-white rounded bg-blue-500 px-1 py-0.5'>必須</span>)}
      </div>
      <div className='flex-1'>
        {children}
      </div>
    </div>
  )
}
