import React, { useState } from "react";
import { AutoFocusDiv } from "../shared/parts/AutoFocusDiv";

// TODO: 微妙だから改善したい
export function SelectWithType<T>(
  { className, currentValue, list, onSelect }:
    { className?: string, currentValue: T, list: [string, T][], onSelect: (selectedItem: T) =>  void}
) {
  const [showPopup, setShowPopup] = useState<boolean>(false)

  const valueText = list.find(([_, value]) => currentValue === value)![0]

  return (
    <span className={`relative ${className}`}>
      <span
        className='cursor-pointer inline-flex justify-center items-center h-[40px] pl-4 pr-2 border rounded border-gray-800 hover:bg-gray-100'
        onClick={() => setShowPopup(true)}
      >
        { valueText }
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/>
        </svg>
      </span>
      {
        showPopup && (
          <AutoFocusDiv
            className='absolute top-[40px] right-0'
            onBlur={() => setShowPopup(false)}
          >
            {
              list.map((v) => {
                const text = v[0]
                return (
                  <div
                    className='
                      flex justify-center items-center cursor-pointer
                      bg-white h-[40px] w-[160px] border
                      hover:bg-gray-500 hover:text-white
                    '
                    onClick={() => {
                      setShowPopup(false)
                      onSelect(v[1])
                    }}>
                    { text }
                  </div>
                )
              })
            }
          </AutoFocusDiv>
        )
      }
    </span>
  )
}