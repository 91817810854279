import React, { useEffect, useState } from "react";
import { Brand } from "../../types/typescript-axios";
import { useManager } from "../../components/contexts/ManagerProvider";
import { useNavigate, useParams } from "react-router-dom";
import { authorizedRequest } from "../../utils/api-request";
import { BrandForm } from "../../components/templates/brands/BrandForm";
import { SidebarLayout } from "../../components/layout/SidebarLayout";

export type BrandUpdateFunction = (data: Partial<Brand> & { id: number, makerId: number }) => void

export const BrandDetail = () => {
  const { id } = useParams()
  const manager = useManager()
  const navigate = useNavigate()
  const [brand, setBrand] = useState<Brand>()

  useEffect(() => {
    fetchBrand()
  }, [])

  async function fetchBrand() {
    const response = await authorizedRequest(manager, 'ShowBrand', { id: Number(id) })
    if (response.status === 200) {
      setBrand(response.data.brand)
    }
  }

  const submit: BrandUpdateFunction = async ({
                                               id, name, subName, akasatana, channel,
                                               searchWord, topPageUrl, purchaseUrl, isPublished, makerId
                                             }) => {
    await authorizedRequest(manager, 'UpdateBrand', {
      id, name, subName, akasatana, channel, searchWord,
      topPageUrl, purchaseUrl, isPublished,
      makerId,
    })
    navigate('/brands/list')
  }

  if (!brand) return <SidebarLayout/>

  return <BrandForm brand={brand} update={submit}/>
}
