export const AdTypeName = {
  'TOP': 'メインディスプレイ',
  'A': '広告A',
  'B': '広告B',
  'C': '広告C',
  'D': '広告D',
  'E': '広告E',
  'BANNER': 'SPポップアップ',
  'APP_ONBOARDING': 'アプリ起動時',
} as const
