import { ReactNode } from "react";

export const LeftLabel = ({ title, children, required = false }: { title: string, children: ReactNode, required?: boolean }) => {
  return (
    <div className='flex mb-[32px]'>
      <div className='w-[300px]'>
        <label>{ title }</label>
        { required && (<span className='ml-2 text-[12px] font-bold text-white rounded bg-blue-500 px-1 py-0.5'>必須</span>)}
      </div>
      <div className='flex-1'>
        { children }
      </div>
    </div>
  )
}
