import React from "react";
import { FetchListView } from "../../components/templates/FetchListView";
import { Qa } from "../../types/typescript-axios";
import { useManager } from "../../components/contexts/ManagerProvider";
import { ElementInList } from "../../components/parts/ElementInList";
import { Link } from "react-router-dom";
import { authorizedRequest } from "../../utils/api-request";

function ListElement({ elem }: { elem: Qa }) {
  return (
    <ElementInList>
      <span>{ elem.id}</span>
      <Link to={`/q-and-a/${elem.id}`} className='ml-4 list-link'>{ elem.title }</Link>
    </ElementInList>
  )
}

export const QandAList: React.FunctionComponent = () => {
  const manager = useManager()

  async function fetch({ query, page, per }: { query: string|null, page: number|null, per: number|null}): Promise<[Qa[], number]> {
    const response = await authorizedRequest(manager, 'IndexQas', {
      ...(query && { query }),
      ...(page && { page }),
      ...(per && { per }),
    })
    if (response.status !== 200) return [[], 0]

    const { data } = response
    return [data.qas, data.count]
  }
  return (
    <FetchListView<Qa>
      fetch={fetch}
      ListElemComp={ListElement}
      title='Q&A管理'
    />
  )
}
