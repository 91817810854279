import { CognitoUserWithChallenge } from "./cognito-authenticator";
import { Configuration } from "../types/typescript-axios";

const BasePath = process.env.REACT_APP_ENV === 'staging' ? '/staging/admin'
  : process.env.REACT_APP_ENV === 'production' ? '/production/admin'
    : '/dev/admin'

export function apiConfiguration(manager: CognitoUserWithChallenge | null): Configuration {
  return new Configuration({basePath: BasePath, apiKey: manager?.getSignInUserSession()?.getIdToken()?.getJwtToken()})
}
