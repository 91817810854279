import { Clinic, ClinicTheme, UserContentStatus } from "../../types/typescript-axios";
import { useParams } from "react-router-dom";
import React, { FormEvent, useEffect, useState } from "react";
import { useManager } from "../../components/contexts/ManagerProvider";
import { toDateTimeISOString, toDateTimeString } from "../../utils/toDateTimeString";
import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { LeftLabel } from "../../components/molecules/LeftLabel";
import { extract } from "../../utils/extract";
import { authorizedRequest } from "../../utils/api-request";
import { useObjectState } from "../../lib/useObjectState";
import { EditImage, ImageListWithInput } from "../../components/parts/ImageListWIthInput";

export const ReportDetail: React.FunctionComponent = () => {
  const manager = useManager()
  const {id} = useParams()
  const [report, setReportParam, setReport] = useObjectState<Clinic>()
  const [themes, setThemes] = useState<ClinicTheme[]>([])
  
  const [reportImage, setReportImageUrl] = [report?.image1Url, report?.image2Url, report?.image3Url, report?.image4Url, report?.image5Url, report?.image6Url]

  const [reportImages, setReportImages] = useState<EditImage[]>([])

  useEffect(() => {
    async function fetchData() {
      const showReportResponse = await authorizedRequest(manager, 'ShowReport', {id: +id!})
      if (showReportResponse.status !== 200) return

      const {data: {report}} = showReportResponse

      const indexReportThemes = await authorizedRequest(manager, 'IndexReportThemes')
      if (indexReportThemes.status !== 200) return
      const { data: { themes }} = indexReportThemes

      setThemes(themes)
      setReport(report)
    }

    fetchData()
  }, [])

  async function updateReport(e: FormEvent) {
    e.preventDefault()
    if (!report) return

    await authorizedRequest(manager, 'UpdateReport', {
      ...extract(report,
        'id', 'clinicName', 'comment',
        'applyDate', 'themeId',
        'statusId',
        'product1', 'product2', 'product3', 'product4',
        'product5', 'product6', 'product7', 'product8',
      )
    })
    window.location.reload()
  }

  return (
    <SidebarLayout>
      <div className='py-4 px-4'>
        <h1>みんなの美容レポ詳細</h1>
        <form onSubmit={updateReport}>
          <LeftLabel title='テーマ'>
            <select value={report?.themeId} onChange={(e) => setReportParam('themeId', Number(e.target.value))}>
              {
                themes?.map(t => (
                  <option value={t.id}>{t.name}</option>
                ))
              }
            </select>
          </LeftLabel>
          <LeftLabel title='タイトル'>
            <input
              value={report?.clinicName}
              onChange={(e) => setReportParam('clinicName', e.target.value)}
            />
          </LeftLabel>
          <LeftLabel title='投稿日時'>{toDateTimeString(report?.applyDate)}</LeftLabel>
          <LeftLabel title='投稿者'>{report?.customer?.nicname}</LeftLabel>
          <LeftLabel title='コメント'>
            <textarea
              value={report?.comment}
              onChange={(e) => setReportParam('comment', e.target.value)}
            />
          </LeftLabel>
          <LeftLabel title='画像'>
            <div className='flex flex-wrap gap-x-8'>
              { report?.image1Url && (<img src={report?.image1Url} className='w-[170px] h-[170px] mt-[8px]' />) }
              { report?.image2Url && (<img src={report?.image2Url} className='w-[170px] h-[170px] mt-[8px]' />) }
              { report?.image3Url && (<img src={report?.image3Url} className='w-[170px] h-[170px] mt-[8px]' />) }
              { report?.image4Url && (<img src={report?.image4Url} className='w-[170px] h-[170px] mt-[8px]' />) }
              { report?.image5Url && (<img src={report?.image5Url} className='w-[170px] h-[170px] mt-[8px]' />) }
              { report?.image6Url && (<img src={report?.image6Url} className='w-[170px] h-[170px] mt-[8px]' />) }
              <ImageListWithInput images={reportImages} setImages={setReportImages}/>
            </div>
          </LeftLabel>
          <LeftLabel title='関連商品コード'>
            {
              ([1,2,3,4,5,6,7,8] as const).map(num => (
                <input
                  key={num} className='flex'
                  value={report && report[`product${num}`]}
                  onChange={(e) => setReportParam(`product${num}`, Number(e.target.value))}
                />
              ))
            }
          </LeftLabel>
          <LeftLabel title='予約投稿日時'>
            <input
              type='datetime-local' value={toDateTimeISOString(report?.applyDate)}
              onChange={(e) => setReportParam('applyDate', e.target.value)}
            />
          </LeftLabel>
          <LeftLabel title='ステータス'>
            <select
              value={report?.statusId}
              onChange={(e) => setReportParam('statusId', Number(e.target.value) as UserContentStatus) }
            >
              <option value={1}>公開</option>
              <option value={2}>非公開</option>
              <option value={8}>下書き</option>
            </select>
          </LeftLabel>
          <div className='flex justify-center items-center gap-x-3'>
            <a className="bg-white text-blue-400 border-2 border-blue-400 hover:text-white rounded-[3px] font-bold
                          w-[200px] h-[50px] flex justify-center items-center hover:bg-blue-400" href="/reports/list">一覧へ戻る</a>
            <button onClick={updateReport}>登録</button>
          </div>
        </form>
      </div>
    </SidebarLayout>
  )

}
