import { Category } from "../../types/typescript-axios";
import React, { useEffect, useState } from "react";
import { useManager } from "../contexts/ManagerProvider";
import { authorizedRequest } from "../../utils/api-request";

type CategoryWithChildren = Category & {
  children?: Category[]
}
function categoriesToTree(categories: CategoryWithChildren[]): CategoryWithChildren[] {
  const categoryHash = categories.reduce((prev: { [id: string]: CategoryWithChildren }, cur) => {
    cur.children = []
    prev[cur.id] = cur
    return prev
  }, {})
  const topCategories: CategoryWithChildren[] = []
  categories.forEach(category => {
    if (!category.parentCategoryId) {
      topCategories.push(category)
    } else {
      categoryHash[category.parentCategoryId]?.children?.push(category)
    }
  })
  return topCategories
}

const hierarchyStyle: { [key: number]: string } = {
  1: 'font-bold',
  2: 'underline',
}

export const ChooseCategoriesModal = (
  {initialCategories, choosed, cancel}:
    { initialCategories: Category[], choosed: (categories: Category[]) => void, cancel: () => void }
) => {
  const manager = useManager()

  const [chosenCategories, setChosenCategories] = useState<Category[]>(initialCategories)
  const [topCategories, setTopCategories] = useState<CategoryWithChildren[]>([])
  const [searchWord, setSearchWord] = useState<string>('')

  useEffect(() => {
    async function fetchCategories() {
      const response = await authorizedRequest(manager, 'IndexCategories', { all: true })
      if (response.status !== 200) return

      const {data: {categories}} = response
      setTopCategories(categoriesToTree(categories))
    }

    fetchCategories()
  }, [])

  function chooseCategories(category: Category) {
    setChosenCategories(categories => {
      if (categories.map(t => t.id)?.includes(category.id)) {
        return categories.filter(t => t.id !== category.id)
      } else {
        return [...categories, category]
      }
    })
  }

  function renderCategory(category: CategoryWithChildren) {
    return (
      <div className='text-[12px] mt-1'>
        <div>
          <input id={`category-check-${category.id}`} type='checkbox' checked={chosenCategories.map(c => c.id)?.includes(category.id)} className='w-[14px]' onChange={() => chooseCategories(category)} />
          <label htmlFor={`category-check-${category.id}`} className={`ml-1 ${hierarchyStyle[category.hierarchy]}`}>{ category.categoryName }</label>
        </div>
        <div className='ml-6'>
          {
            category.children?.map((c) => ((
              <div key={c.id}>{ renderCategory(c) }</div>
            )))
          }
        </div>
      </div>
    )
  }

  return (
    <div className='fixed h-screen w-screen top-0 left-0 z-50 flex justify-center items-center'
         style={{backgroundColor: 'rgba(0, 0, 0, .5)'}}>
      <div className='w-[80%] h-[80%] bg-white rounded-3xl p-[32px] overflow-scroll'>
        <h2>カテゴリの選択</h2>
        <p>下記からカテゴリを選択してください。</p>
        <div className='mt-4 w-[90%] mx-auto max-h-[80%] overflow-scroll border rounded border-gray-500 p-6'>
          {
            topCategories?.map((top) => ((
              <div key={top.id}>{ renderCategory(top) }</div>
            )))
          }
        </div>
        <div className='flex justify-center mt-[24px]'>
          <button className='gray-btn' onClick={cancel}>キャンセル</button>
          <button className='ml-14' onClick={() => choosed(chosenCategories)}>選択</button>
        </div>
      </div>
    </div>
  )
}
