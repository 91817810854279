import React, { FormEvent } from "react";
import { GlobalNotification } from "../../../types/typescript-axios";
import { useObjectState } from "../../../lib/useObjectState";
import {
  GlobalNotificationLinkType,
  GlobalNotificationLinkTypes
} from "../../../models/global-notification/global-notification-link-type";
import { toDateTimeISOString, toDateTimeString } from "../../../utils/toDateTimeString";


export type GlobalNotificationFormData = {
  title: string
  linkType?: GlobalNotificationLinkType
  linkValue?: string
  notifiedAt: string
}
type UpdateProps = {
  type: 'update'
  globalNotification: GlobalNotification
  update: (data: GlobalNotificationFormData & { id: number }) => void
}
type CreateProps = {
  type: 'create'
  create: (data: GlobalNotificationFormData) => void
}

type Props = UpdateProps | CreateProps

export const GlobalNotificationForm = (props: Props) => {
  const globalNotification = props.type === 'update' ? props.globalNotification : undefined
  const [data, setObjectData] = useObjectState<GlobalNotificationFormData>({
    title: globalNotification?.title || '',
    linkType: globalNotification?.linkType || 'info',
    linkValue: globalNotification?.linkValue || '',
    notifiedAt: globalNotification?.notifiedAt ? toDateTimeISOString(globalNotification.notifiedAt) : '',
  })

  function submit(e: FormEvent) {
    e.preventDefault()
    if (props.type === 'update') props.update({
      id: props.globalNotification.id,
      ...data,
    })
    else props.create(data)
  }

  return (
    <div className='w-[520px] h-[360px]'>
      <h2>通知の{ globalNotification ? '更新' : '新規作成' }</h2>
      <form onSubmit={submit} className='flex flex-col gap-8 mt-32'>
        {
          globalNotification && (
            <div>{ globalNotification.id }</div>
          )
        }
        <input
          required
          placeholder='名前を入力'
          value={data.title}
          onChange={(e) => setObjectData('title', e.target.value)}
          className='inline-block w-full'
        />
        <select
          value={data.linkType}
          onChange={(e) => setObjectData('linkType', e.target.value as GlobalNotificationLinkType)}
        >
          {
            GlobalNotificationLinkTypes.map((type, index) => (
              <option key={index} value={type[0]}>{ type[1] }</option>
            ))
          }
        </select>
        <input
          placeholder='リンク先の値'
          value={data.linkValue}
          onChange={(e) => setObjectData('linkValue', e.target.value)}
        />
        <input
          required
          type='datetime-local'
          value={data.notifiedAt}
          onChange={(e) => setObjectData('notifiedAt', e.target.value)}
        />

        <button className='mt-32 small-btn'>作成</button>
      </form>
    </div>
  )
}
