import React, { FormEvent, useContext, useEffect, useState } from "react";
import { CognitoUserWithChallenge, completeNewPassword } from "../../config/cognito-authenticator";
import { ManagerContext } from "../../components/contexts/ManagerProvider";
import { useNavigate } from "react-router-dom";

export const NewPasswordRequired: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const [manager, setManager] = useContext(ManagerContext)
  const [password, setPassword] = useState<string>('a')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('a')
  const [errorStatement, setErrorStatement] = useState<string|null>(null)

  useEffect(() => {
    if (!manager) navigate('/login')
  })

  async function submit(e: FormEvent) {
    e.preventDefault()
    setErrorStatement(null)

    if (password !== passwordConfirm) return setErrorStatement('パスワードが一致しません。')

    const result = await completeNewPassword(manager as CognitoUserWithChallenge, password)
    if (result === 'INVALID_PASSWORD') {
      setErrorStatement('パスワードは12文字以上、大文字英字、小文字英字、数字を少なくとも1つずつ利用してください')
    } else {
      setManager(result)
      navigate('/')
    }
  }

  return (
    <div className="bg-gray-100 h-screen w-screen flex justify-center items-center">
      <form onSubmit={submit} className="flex flex-col items-center min-h-96 w-96 bg-white px-8 py-8">
        <h1>パスワードの設定が必要です。</h1>
        <input required className="block mb-4" type='password' autoFocus={true} placeholder="パスワード" value={password} onChange={(e) => setPassword(e.target.value)} />
        <input required className="block mb-8"  type='password' placeholder="パスワード確認" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
        { errorStatement && <p className="text-red-500 text-sm mb-4">{ errorStatement }</p>}
        <button>設定</button>
      </form>
    </div>
  )
}