import React, { FormEvent, useContext, useEffect, useState } from "react";
import { signIn } from "../config/cognito-authenticator";
import { ManagerContext } from "../components/contexts/ManagerProvider";
import { useNavigate } from "react-router-dom";

export const Login: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errorStatement, setErrorStatement] = useState<string>()

  const [manager, setManager] = useContext(ManagerContext)

  useEffect(() => {
    if (manager) navigate('/')
  }, [manager, navigate])

  async function submit(e: FormEvent) {
    e.preventDefault()
    const result = await signIn(email, password)
    console.log(result)
    if (result === 'INCORRECT_PASSWORD') {
      setErrorStatement('ユーザ名またはパスワードが間違ってます')
    } else {
      setManager(result)
    }
  }

  return (
    <div className="bg-gray-100 h-screen w-screen flex justify-center items-center">
      <form onSubmit={submit} className="flex flex-col items-center h-96 w-96 bg-white px-8 py-8">
        <h1>COSMEbi管理画面</h1>
        <input className="block mb-4" autoFocus={true} placeholder="ログインID" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input className="block mb-8"  type='password' placeholder="パスワード" value={password} onChange={(e) => setPassword(e.target.value)} />
        { errorStatement && <p className="text-red-500 text-sm mb-4">{ errorStatement }</p>}
        <button>ログイン</button>
      </form>
    </div>
  )
}
